// src/components/Input.js

import React from 'react';
import styled from 'styled-components';

import { align, borderRadii, colors, fontSizes, fontWeights, widths } from '../constants';
import { margin, padding, insetBoxShadow } from '../utils/styleUtils';

const Input = styled(
  ({
    alignItems,
    alignSelf,
    backgroundColor,
    bodyId,
    border,
    borderRadius,
    children,
    click,
    color,
    column,
    completed,
    cursor,
    dispatch,
    flex,
    flexDirection,
    flexGrow,
    flexShrink,
    flexWrap,
    focus,
    fontSize,
    hover,
    inset,
    justifyContent,
    m,
    mb,
    ml,
    mr,
    mt,
    noBorder,
    noOverflow,
    p,
    pb,
    pl,
    pr,
    pt,
    innerRef,
    staticContext,
    textAlign,
    width,
    wrap,
    ...rest
  }) => <input type="text" {...rest} />
)`
  background-color: ${({ backgroundColor: bc }) => colors[bc || 'transparent']};
  border-radius: ${borderRadii.round};
  color: ${colors.black};
  flex: 0 1 auto;
  ${({ flexGrow: fg }) => fg && `flex-grow: ${fg}`};
  font-size: ${fontSizes.m};
  font-weight: ${fontWeights.m};
  ${props => margin(props)};
  min-height: 40px;
  ${props => padding(props)};
  text-align: ${({ textAlign: ta }) => align[ta]};
  &:-webkit-autofill {
    background-color: ${colors.transparent} !important;
  }
  min-width: 50px;
  width: ${({ width }) => widths[width]};
  ${insetBoxShadow};
  &::placeholder {
    color: ${colors.black};
    opacity: 0.5;
  }
`;

Input.defaultProps = {
  p: 'm',
  textAlign: 'left',
  width: 'auto'
};

export default Input;

// src/constants.js

// flex alignments
export const align = {
  column: 'column',
  center: 'center',
  flexEnd: 'flex-end',
  flexStart: 'flex-start',
  left: 'left',
  right: 'right',
  spaceAround: 'space-around',
  spaceBetween: 'space-between',
  top: 'flex-start'
};

// api bases
export const apiBase = '/api';
export const apiEventBase = `${apiBase}/event`;
export const apiActivityBase = `${apiBase}/activity`;

// icons
export const appStore = 'https://twos.s3.amazonaws.com/appStore.svg';
export const googlePlay = 'https://twos.s3.amazonaws.com/googlePlay.png';

// border radii
export const borderRadii = {
  n: '0',
  square: '0',
  round: '4px',
  button: '44px',
  circle: '50%'
};

// colors
export const colors = {
  black: '#000',
  darkGrey: '#777',
  // green: '#78e9c0',
  green: '#7bc96f',
  grey: '#ccc',
  haze: 'rgba(255, 255, 255, 0.7)',
  // hoverGrey: '#fafafa',
  hoverGrey: '#f2f2f2',
  lightGrey: '#f2f2f2',
  red: '#ff8080',
  transparent: 'transparent',
  white: '#fff',
  yellow: '#ffd600',

  // event colors
  lightRed: '#FFCDD2',
  darkRed: '#F44336',
  lightOrange: '#FFE0B2',
  darkOrange: '#FF9800',
  lightYellow: '#FFF9C4',
  darkYellow: '#FFEB3B',
  lightGreen: '#C8E6C9',
  darkGreen: '#4CAF50',
  lightBlue: '#BBDEFB',
  darkBlue: '#2196F3',
  lightPurple: '#E1BEE7',
  darkPurple: '#9C27B0',
  lightPink: '#F8BBD0',
  darkPink: '#E91E63',
  lightBrown: '#D7CCC8',
  darkBrown: '#795548',
  lightGray: '#F5F5F5',
  darkGray: '#9E9E9E'
};

export const lightColorToColor = {
  '#FFCDD2': 'red',
  '#FFE0B2': 'orange',
  '#FFF9C4': 'yellow',
  '#C8E6C9': 'green',
  '#BBDEFB': 'blue',
  '#E1BEE7': 'purple',
  '#F8BBD0': 'pink',
  '#D7CCC8': 'brown',
  '#F5F5F5': 'gray'
};

export const colorToRGB = {
  red: [244, 67, 54],
  orange: [255, 152, 0],
  yellow: [255, 235, 59],
  green: [76, 175, 80],
  blue: [33, 150, 243],
  purple: [156, 39, 176],
  pink: [233, 30, 99],
  brown: [121, 85, 72],
  gray: [158, 158, 158]
};

export const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export const eventColors = [
  colors.lightBlue,
  colors.lightGreen,
  colors.lightYellow,
  colors.lightOrange,
  colors.lightRed,
  colors.lightPink,
  colors.lightPurple,
  colors.lightBrown,
  colors.lightGray
];

// font sizes
export const fontSizes = {
  n: '0',
  xs: '0.75rem',
  s: '1rem',
  m: '1.25rem',
  l: '1.5rem',
  xl: '1.25rem',
  xxl: '1.5rem',
  xxxl: '2rem'
};

// font weights
export const fontWeights = {
  light: '200',
  m: '500',
  bold: '600'
};

// icon sizes
export const iconSizes = {
  s: 16,
  m: 24,
  l: 32
};

// image sizes
export const imgSizes = {
  s: '32px',
  m: '40px',
  l: '48px'
};

// months
export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

// months
export const monthToNumber = {
  January: 0,
  February: 1,
  March: 2,
  April: 3,
  May: 4,
  June: 5,
  July: 6,
  August: 7,
  September: 8,
  October: 9,
  November: 10,
  December: 11
};

// const sizeFactor = { value: 2, type: 'rem' };
// sizes
export const sizes = {
  // none: 0 * sizeFactor.value) + sizeFactor.type,
  n: '0',
  xs: '0.25rem',
  s: '0.5rem',
  m: '1rem',
  l: '1.5rem',
  xl: '2rem',
  xxl: '2.5rem',
  xxxl: '3rem',
  full: '100%'
};

// weekDays
export const weekDays = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
];

// shortDays
export const shortDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

// widths
export const widths = {
  auto: 'auto',
  contain: '900px',
  full: '100%',
  half: '50%',
  s: '400px'
};

export const charts = {
  BAR: 'Bar',
  DAYS: 'Days',
  GRID: 'Grid',
  LINE: 'Line',
  PIE: 'Pie'
};

// short months
export const shortMonths = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

// times
export const times = {
  WEEK: 'Week',
  MONTH: 'Month',
  YEAR: 'Year',
  ALL_TIME: 'All-Time'
};

// types
export const types = {
  BOOLEAN: 'boolean',
  COUNT: 'count',
  SCALE: 'scale',
  TEXT: 'text'
};

// goal values
export const goalValues = {
  HIT: 'Hit',
  MISS: 'Miss'
};

// values
export const values = {
  NO: 'No',
  YES: 'Yes',
  ZERO: '0',
  ONE: '1'
};

import { titleToDate } from './dateUtils';

export const sortByCreated = (a, b) => {
  const dateA = new Date(a.created);
  const dateB = new Date(b.created);
  return dateA < dateB ? -1 : dateA > dateB ? 1 : 0;
};

export const sortByDate = (a, b) => {
  const dateA = new Date(titleToDate(a.date));
  const dateB = new Date(titleToDate(b.date));
  return dateA < dateB ? -1 : dateA > dateB ? 1 : 0;
};

export const sortByUpdated = (a, b) => {
  const dateA = new Date(a.updated);
  const dateB = new Date(b.updated);
  return dateA < dateB ? -1 : dateA > dateB ? 1 : 0;
};

// src/views/DayView.js

import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import * as activityActions from '../actions/activityActions';
import * as dayActions from '../actions/dayActions';
import * as eventActions from '../actions/eventActions';
import * as sideMenuActions from '../actions/sideMenuActions';
import Body from '../components/Body';
import Box from '../components/Box';
import Button from '../components/Button';
import Header from '../components/Header';
import HeaderButton from '../components/HeaderButton';
import Icon from '../components/Icon';
import Loading from '../components/Loading';
import Text from '../components/Text';
import TextArea from '../components/TextArea';
import Title from '../components/Title';
import View from '../components/View';
import { types, values } from '../constants';
import CalendarModal from '../modals/CalendarModal';
import StreakModal from '../modals/StreakModal';
import Activity from '../objects/Activity';
import { getEventActivities } from '../utils/dataUtils';
import {
  dateToTitle,
  getNextDate,
  getPastDate,
  titleToDate,
  titleToTitle
} from '../utils/dateUtils';
import {
  blurElementById,
  getElementById,
  scrollToElementById,
  focusElementById
} from '../utils/elementUtils';
import { calculateRemainingString } from '../utils/numberUtils';
import { sortByDate, sortByUpdated } from '../utils/sortUtils';
import { getCurrentStreak, getIsStreaking, getLongestStreak } from '../utils/statUtils';
import { colorToLightColor } from '../utils/stringUtils';

class DayView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      longestStreak: 0,
      showCalendar: false,
      showStreak: false,
      streak: 0,
    };

    this.beforeUnload = this.beforeUnload.bind(this);
    this.checkActivityStreak = this.checkActivityStreak.bind(this);
    this.handleBlurActivity = this.handleBlurActivity.bind(this);
    this.handleChangeActivity = this.handleChangeActivity.bind(this);
    this.handleChangeEventIncrementValue = this.handleChangeEventIncrementValue.bind(this);
    this.handleFocusActivity = this.handleFocusActivity.bind(this);
    this.handleLoadEvent = this.handleLoadEvent.bind(this);
    this.handleLoadToday = this.handleLoadToday.bind(this);
    this.handleNextDay = this.handleNextDay.bind(this);
    this.handlePastDay = this.handlePastDay.bind(this);
    this.handleSaveActivity = this.handleSaveActivity.bind(this);
    this.handleSaveEvent = this.handleSaveEvent.bind(this);
    this.renderValue = this.renderValue.bind(this);
  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.beforeUnload);
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.beforeUnload);
  }

  beforeUnload() {
    this.handleBlurActivity();
  }

  handleBlurActivity() {
    const { activity, saveActivity } = this.props;

    saveActivity(activity);
  }

  checkActivityStreak(newActivity) {
    const { activities, events } = this.props;

    const event = events.find(e => e._id === newActivity.event_id);

    if (event.type === types.SCALE) return;
    if (!event.goal) return;

    let eventActivities = getEventActivities(event, activities);
    eventActivities = eventActivities.sort(sortByDate);

    const activityIndex = eventActivities.findIndex(a => a._id === newActivity._id);

    let newActivities = [ ...eventActivities ];

    // new activity
    if (activityIndex === -1) {
      newActivities = [...newActivities, newActivity];
    } else {
      newActivities[activityIndex] = newActivity;
    }

    let oldStreak = getCurrentStreak(eventActivities, event);
    const isStreaking = getIsStreaking(eventActivities);
    if (!isStreaking) {
      oldStreak = 0;
    }
    const streak = getCurrentStreak(newActivities, event);

    if (streak > oldStreak) {
      const longestStreak = getLongestStreak(newActivities, event);
      this.setState({ name: event.name, longestStreak, showStreak: true, streak })
    }
  }

  handleChangeActivity(value) {
    const { activity, changeActivity } = this.props;

    this.checkActivityStreak({ ...activity, value });

    changeActivity(value);
  }

  handleChangeEventIncrementValue(event, incrementValue) {
    const { changeEventIncrementValue } = this.props;

    changeEventIncrementValue(event, incrementValue);
  }

  handleFocusActivity(activity) {
    const { createActivity, day, editActivity, user } = this.props;
    let newActivity = activity;

    if (!newActivity._id) {
      newActivity.date = day;
      newActivity.user_id = user._id;
      newActivity = new Activity(newActivity);
      createActivity(newActivity);
      return;
    }

    editActivity(newActivity);
  }

  handleLoadEvent(event) {
    const { loadEvent, history } = this.props;

    loadEvent(event);

    history.push('/event');
  }

  handleLoadToday() {
    const { loadDay } = this.props;

    const date = new Date();

    loadDay(dateToTitle(date));
  }

  handleNextDay() {
    const { day, loadDay } = this.props;

    let date = new Date();

    if (day) date = titleToDate(day);

    loadDay(dateToTitle(getNextDate(date)));
  }

  handlePastDay() {
    const { day, loadDay } = this.props;

    let date = new Date();

    if (day) date = titleToDate(day);

    loadDay(dateToTitle(getPastDate(date)));
  }

  handleSaveActivity(activity) {
    const { createActivity, day, saveActivity, user } = this.props;

    let newActivity = { ...activity };

    if (!newActivity._id) {
      newActivity.date = day;
      newActivity.user_id = user._id;
      newActivity = new Activity(newActivity);
      this.checkActivityStreak(newActivity);
      createActivity(newActivity);
      return;
    }

    this.checkActivityStreak(newActivity);
    saveActivity(newActivity);
  }

  handleSaveEvent(event) {
    this.props.saveEvent(event);
  }

  renderValue(activity, event) {
    const { events } = this.props;

    const eventIndex = events.findIndex(e => e._id === event._id);
    const isLast = eventIndex === events.length - 1;

    switch (event.type) {
      case types.BOOLEAN:
        return (
          <Fragment>
            <Button
              flexGrow={1}
              backgroundColor={
                activity.value === values.ZERO
                  ? event.goal === values.ZERO ? 'green' : 'red'
                  : null
              }
              onClick={e => {
                e.stopPropagation();
                this.handleSaveActivity({
                  ...activity,
                  value: activity.value === values.ZERO ? '' : values.ZERO
                });
              }}
            >
              No
            </Button>
            <Button
              flexGrow={1}
              backgroundColor={
                activity.value === values.ONE
                  ? event.goal === values.ZERO ? 'red' : 'green'
                  : null
              }
              ml="m"
              onClick={e => {
                e.stopPropagation();
                this.handleSaveActivity({
                  ...activity,
                  value: activity.value === values.ONE ? '' : values.ONE
                });
              }}
            >
              Yes
            </Button>
          </Fragment>
        );
      case types.COUNT:
        return (
          <Box flexDirection="column">
            <Box alignItems="center" pb="m">
              <TextArea
                flexGrow={1}
                flexShrink={1}
                id={`input${event._id}`}
                backgroundColor={
                  event.goal && Number(activity.value) >= Number(event.goal) ? 'green' : null
                }
                onBlur={this.handleBlurActivity}
                onChange={e => this.handleChangeActivity(e.target.value === '' ? '' : e.target.value === '0' ? '0' : Number(e.target.value) > 0 ? e.target.value : activity.value)}
                onClick={e => e.stopPropagation()}
                onFocus={e => {
                  e.stopPropagation();
                  this.handleFocusActivity(activity);
                }}
                onKeyDown={e => {
                  if (e.keyCode === 13) {
                    e.preventDefault();

                    if (isLast) {
                      return;
                    }

                    const nextEvent = events[eventIndex + 1];
                    const nextEventInputId = `input${nextEvent._id}`;

                    if (getElementById(nextEventInputId)) {
                      scrollToElementById(nextEvent._id);
                      focusElementById(nextEventInputId);
                    } else {
                      blurElementById(`input${event._id}`);
                      scrollToElementById(nextEvent._id);
                    }
                  }
                }}
                placeholder="0"
                textAlign="center"
                value={activity.value ? String(activity.value) : ''}
                width="auto"
              />
              {!!event.goal && (
                <Box alignItems="center" width="auto">
                  <Text flexShrink="0" ml="m" textAlign="center" width="auto">
                    / {event.goal}
                  </Text>
                  <Text flexShrink="0" color="darkGrey" ml="m">
                    ({calculateRemainingString(activity.value, event.goal)})
                  </Text>
                </Box>
              )}
            </Box>
            <Box>
              <Button
                flexGrow={1}
                onClick={e => {
                  e.stopPropagation();
                  this.handleSaveActivity({
                    ...activity,
                    value: String((Number(activity.value) || 0) - (Number(event.incrementValue) || 1))
                  });
                }}
              >
                <Icon type="dash" />
              </Button>
              <TextArea
                flexShrink={1}
                id={`incrementValue${event._id}`}
                ml="m"
                onBlur={() => {
                  this.handleSaveEvent(event);
                  this.handleBlurActivity();
                }}
                onChange={e => this.handleChangeEventIncrementValue(event, e.target.value === '' ? '' : e.target.value === '0' ? '' : Number(e.target.value) > 0 ? e.target.value : event.incrementValue)}
                onClick={e => e.stopPropagation()}
                onFocus={e => e.stopPropagation()}
                onKeyDown={e => {
                  if (e.keyCode === 13) {
                    e.preventDefault();

                    if (event.incrementValue) {
                      if (e.shiftKey) {
                        this.handleSaveActivity({
                          ...activity,
                          value: String((Number(activity.value) || 0) - Number(event.incrementValue))
                        });
                      } else {
                        this.handleSaveActivity({
                          ...activity,
                          value: String((Number(activity.value) || 0) + Number(event.incrementValue))
                        });
                      }
                    }
                  }
                }}
                placeholder="1"
                textAlign="center"
                value={event.incrementValue || ''}
                width="25%"
              />
              <Button
                flexGrow={1}
                ml="m"
                onClick={e => {
                  e.stopPropagation();
                  this.handleSaveActivity({
                    ...activity,
                    value: String((Number(activity.value) || 0) + (Number(event.incrementValue) || 1))
                  });
                }}
              >
                <Icon type="add" />
              </Button>
            </Box>
          </Box>
        );
      case types.SCALE: {
        const buttons = new Array(Number(event.goal) || 5).fill('button');
        return buttons.map((elem, index) => (
          <Button
            backgroundColor={activity.value === String(index + 1) ? 'green' : null}
            flexGrow={1}
            key={index}
            ml="s"
            mr="s"
            noMin
            onClick={e => {
              e.stopPropagation();
              this.handleSaveActivity({
                ...activity,
                value: activity.value === String(index + 1) ? '' : String(index + 1)
              });
            }}
          >
            {index + 1}
          </Button>
        ));
      }
      default:
        return (
          <TextArea
            flexGrow={1}
            id={`input${event._id}`}
            onBlur={this.handleBlurActivity}
            onChange={e => this.handleChangeActivity(e.target.value)}
            onClick={e => e.stopPropagation()}
            onFocus={e => {
              e.stopPropagation();
              this.handleFocusActivity(activity);
            }}
            onKeyDown={e => {
              if (e.keyCode === 13) {
                e.preventDefault();

                if (isLast) {
                  return;
                }

                const nextEvent = events[eventIndex + 1];
                const nextEventInputId = `input${nextEvent._id}`;

                if (getElementById(nextEventInputId)) {
                  scrollToElementById(nextEvent._id);
                  focusElementById(nextEventInputId);
                } else {
                  blurElementById(`input${event._id}`);
                  scrollToElementById(nextEvent._id);
                }
              }
            }}
            textAlign="center"
            value={activity.value || ''}
            placeholder="Type here..."
          />
        );
    }
  }

  render() {
    const { activities, activity, day, events, history, loading, loadingAll, toggleSideMenu } = this.props;
    const { showCalendar, showStreak } = this.state;

    const isToday = day === dateToTitle(new Date());

    return (
      <View>
        <Header border>
          <HeaderButton onClick={toggleSideMenu}>
            <Icon type="sideMenu" />
          </HeaderButton>
          <Box alignItems="center" justifyContent="spaceBetween">
            <HeaderButton m="n m" hover onClick={this.handlePastDay}>
              <Icon type="back" />
            </HeaderButton>
            <Title onClick={() => this.setState({ showCalendar: true })} width="full">
              {isToday ? 'Today' : titleToTitle(day)}
            </Title>
            <HeaderButton m="n m" hover onClick={this.handleNextDay}>
              <Icon type="forward" />
            </HeaderButton>
          </Box>
          <HeaderButton onClick={() => history.push('/all')}>
            <Icon type="grid" />
          </HeaderButton>
        </Header>
        {((isToday && loading) || (!isToday && loadingAll)) && <Loading />}
        <Body p="m m n">
          {!events.length && (
            <Button onClick={() => history.push('/newEvent')} width="full">
              <Icon mr="s" type="add" />
              New activity
            </Button>
          )}
          <Box className="events">
            {events.map(event => {
              let a = activities.find(act => act.event_id === event._id && act.date === day);
              if (a) a = { ...a, type: event.type };
              if (!a) a = { event_id: event._id, type: event.type };
              if (!!a._id && a._id === activity._id) a = { ...activity, type: event.type };

              return (
                <Box
                  alignItems="center"
                  backgroundColor={event.color && colorToLightColor(event.color)}
                  borderRadius="round"
                  className="event"
                  flexDirection="column"
                  id={event._id}
                  key={event.name}
                  mb="m"
                  p="m"
                >
                  <Text
                    mb="m"
                    onClick={() => this.handleLoadEvent(event)}
                  >
                    {event.name}
                    {event.units ? ` (${event.units})` : null}
                  </Text>
                  <Box alignItems="center" justifyContent="spaceAround" wrap>
                    {this.renderValue(a, event)}
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Body>
        {!!events.length && (
          <Header>
            <Button onClick={() => history.push('/newEvent')} width="full">
              <Icon mr="s" type="add" />
              New activity
            </Button>
          </Header>
        )}
        {showCalendar && (
          <CalendarModal
            onClose={() => this.setState({ showCalendar: false })}
          />
        )}
        {showStreak && (
          <StreakModal
            onClose={() => this.setState({ name: '', longestStreak: 0, showStreak: false, streak: 0 })}
            {...this.state}
          />
        )}
      </View>
    );
  }
}

DayView.propTypes = {
  activities: PropTypes.array.isRequired,
  activity: PropTypes.object.isRequired,
  changeActivity: PropTypes.func.isRequired,
  createActivity: PropTypes.func.isRequired,
  changeEventIncrementValue: PropTypes.func.isRequired,
  day: PropTypes.string.isRequired,
  editActivity: PropTypes.func.isRequired,
  events: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  loadDay: PropTypes.func.isRequired,
  loadEvent: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  loadingAll: PropTypes.bool.isRequired,
  saveActivity: PropTypes.func.isRequired,
  saveEvent: PropTypes.func.isRequired,
  toggleSideMenu: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
};

const mapStateToProps = ({ activities, activity, day, events, loading, loadingAll, user }) => ({
  activities,
  activity,
  day,
  events: events.filter(event => !event.hidden).sort(sortByUpdated),
  loading,
  loadingAll,
  user
});

export default connect(mapStateToProps, {
  ...activityActions,
  ...dayActions,
  ...eventActions,
  ...sideMenuActions
})(DayView);

// src/components/Button.js

import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { align, borderRadii, colors, fontSizes, fontWeights, widths } from '../constants';
import { buttonShadow, hoverShadow, margin, padding } from '../utils/styleUtils';

const Button = styled(
  ({
    alignItems,
    backgroundColor,
    borderRadius,
    boxShadow: bs,
    button,
    children,
    cursor,
    dispatch,
    display,
    flex,
    flexDirection,
    flexGrow,
    flexShrink,
    flexWrap,
    hide,
    hover,
    href,
    justifyContent,
    m,
    mb,
    ml,
    mr,
    mt,
    mx,
    my,
    noHover,
    noMin,
    noShadow,
    p,
    pb,
    pl,
    pr,
    pt,
    px,
    py,
    pointer,
    s3Url,
    staticContext,
    textAlign,
    to,
    type,
    uploadedFiles,
    uploaderOptions,
    width,
    ...rest
  }) =>
    to ? (
      <Link className="button" href={href} to={to} {...rest}>
        {children}
      </Link>
    ) : href ? (
      <a className="button" href={href} {...rest}>
        {children}
      </a>
    ) : (
      <button className="button" type={type || 'button'} {...rest}>
        {children}
      </button>
    )
)`
  align-items: ${({ alignItems: ai }) => align[ai] || align.center};
  ${({ alignSelf: as }) => as && `align-self: ${align[as]}`};
  background-color: ${({ backgroundColor: bc, hide }) =>
    colors[bc] || bc || colors[hide ? 'transparent' : 'white']} !important;
  border-radius: ${({ borderRadius: br }) => borderRadii[br] || br || borderRadii.button};
  color: ${({ color: c }) => colors[c] || colors.black};
  cursor: ${({ hide, noHover, pointer }) =>
    (hide || noHover) && !pointer ? 'default !important' : 'pointer'};
  ${({ disabled }) => disabled && 'cursor: not-allowed; opacity: 0.2'};
  ${({ display }) => `display: ${display || 'flex'}`};
  ${({ flex: f }) => f && `flex: ${f}`};
  ${({ flexDirection: fd }) => fd && `flex-direction: ${align[fd]}`};
  ${({ flexGrow: fg }) => fg && `flex-grow: ${fg}`};
  ${({ flexShrink: fs }) => fs && `flex-shrink: ${fs}`};
  ${({ flexWrap: fw }) => fw && `flex-wrap: ${fw}`};
  font-size: ${({ fontSize: fs }) => fontSizes[fs] || fs || fontSizes.m};
  font-weight: ${({ fontWeight: fw }) => fontWeights[fw] || fontWeights.m};
  ${({ height }) => height && `height: ${height}`};
  justify-content: ${({ justifyContent: jc }) => align[jc] || align.center};
  ${props => margin(props)};
  ${({ noMin }) => !noMin && 'min-width: 56px'};
  ${props => padding(props)};
  ${({ textAlign: ta }) => ta && `text-align: ${ta}`};
  text-decoration: none;
  ${({ width: w }) => w && `width: ${widths[w] || w}`};
  ${({ hide, hover, noShadow }) => !hover && !hide && !noShadow && buttonShadow};
  ${({ hover }) => hover && hoverShadow};
`;

Button.defaultProps = {
  flex: '0 0 auto',
  p: 'm'
};

export default Button;

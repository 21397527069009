import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Confetti from 'react-dom-confetti';

import Box from '../components/Box';
import Button from '../components/Button';
import Modal from '../components/Modal';
import Text from '../components/Text';
import Title from '../components/Title';

const StreakModal = ({ name, longestStreak, onClose, streak }) => {
  const [hasMounted, setHasMounted] = useState(false);

  // eslint-disable-next-line
  useEffect(() => (hasMounted ? false : setHasMounted(true)), []);

  return (
    <Modal onClick={onClose} width="contain">
      <Box alignItems="center" flexDirection="column" p="m">
        <Title p="m">
          {`New ${longestStreak === streak ? 'longest ' : ''}${name} streak!`}
        </Title>
        <Confetti active={hasMounted} />
        <Title fontSize="xxxl">{streak}</Title>
        <Text color="grey" pt="m" pb="s">({longestStreak})</Text>
        <Box p="m">
          <Button backgroundColor="green" id="streakButton" onClick={onClose} width="full">
            Done
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

StreakModal.propTypes = {
  name: PropTypes.string.isRequired,
  longestStreak: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  streak: PropTypes.number.isRequired,
};

export default StreakModal;

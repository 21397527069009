import initialState from './initialState';
import ActionTypes from '../actions/actionTypes';

const editUserReducer = (state = initialState.editUser, action) => {
  switch (action.type) {
    case ActionTypes.CHANGE_CODE:
      return { ...state, code: action.code };

    case ActionTypes.CHANGE_NAME:
      return { ...state, name: action.name };

    case ActionTypes.CHANGE_EMAIL:
    case ActionTypes.SET_EMAIL:
      return { ...state, email: action.email };

    case ActionTypes.CHANGE_PASSWORD:
      return { ...state, password: action.password };

    default:
      return state;
  }
};

export default editUserReducer;

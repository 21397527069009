// src/components/Clickable.js

import React from 'react';
import styled from 'styled-components';

import { align, borderRadii, colors, fontSizes, sizes, widths } from '../constants';
import {
  boxShadow,
  buttonShadow,
  clickShadow,
  hoverShadow,
  margin,
  padding
} from '../utils/styleUtils';

const Clickable = styled(
  ({
    alignItems,
    alignSelf,
    animateHeight,
    animateWidth,
    backgroundColor,
    bodyId,
    border,
    borderRadius,
    boxShadow,
    children,
    click,
    color,
    column,
    completed,
    containerId,
    cursor,
    dispatch,
    flex,
    flexDirection,
    flexGrow,
    flexShrink,
    flexWrap,
    focused,
    fontSize,
    height,
    hover,
    inset,
    justifyContent,
    m,
    mb,
    ml,
    mr,
    mt,
    mx,
    my,
    nextTime,
    noBorder,
    noOverflow,
    pastTime,
    p,
    pb,
    pl,
    pr,
    pt,
    px,
    py,
    position,
    innerRef,
    staticContext,
    textAlign,
    width,
    wrap,
    zIndex,
    ...rest
  }) => (
    <div {...rest} role="button" tabIndex={-1} ref={innerRef}>
      {children}
    </div>
  )
)`
  ${({ alignItems: ai }) => ai && `align-items: ${align[ai]}`};
  ${({ alignSelf: as }) => as && `align-self: ${align[as]}`};
  background-color: ${({ backgroundColor: bc }) => colors[bc] || bc};
  border-radius: ${({ borderRadius: br }) => borderRadii[br] || br};
  ${({ boxShadow: bs }) => bs && boxShadow};
  ${({ border }) => border && `border: ${border}`};
  ${({ button }) => button && buttonShadow};
  ${({ click }) => click && clickShadow};
  color: ${({ color }) => colors[color || 'black']};
  ${({ button, click, cursor, hover }) =>
    (button || cursor || click || hover) && 'cursor: pointer'};
  display: ${({ display: d }) => d || 'flex'};
  ${({ flex: f }) => f && `flex: ${f}`};
  ${({ column: c, flexDirection: fd }) =>
    (fd || c) && `flex-direction: ${align[fd || (c && 'column')]}`};
  ${({ flexGrow: fg }) => fg && `flex-grow: ${fg}`};
  ${({ flexShrink: fs }) => fs && `flex-shrink: ${fs}`};
  ${({ wrap }) => wrap && 'flex-wrap: wrap'};
  ${({ justifyContent: jc }) => jc && `justify-content: ${align[jc]}`};
  font-size: ${({ fontSize }) => fontSizes[fontSize || 'm']};
  ${({ height: h }) => h && `height: ${sizes[h] || h}`};
  ${({ hover }) => hover && hoverShadow};
  ${props => margin(props)};
  ${({ noOverflow: no }) => no && 'overflow: hidden'};
  ${props => padding(props)};
  text-align: ${({ textAlign: ta }) => ta || 'left'};
  text-decoration: none;
  ${({ width: w }) => w && `width: ${widths[w] || w}`};
`;

export default Clickable;

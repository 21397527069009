import initialState from './initialState';
import ActionTypes from '../actions/actionTypes';

const dayReducer = (state = initialState.day, action) => {
  switch (action.type) {
    case ActionTypes.LOAD_DAY:
      return action.day;

    default:
      return state;
  }
};

export default dayReducer;

// src/views/Grid.js

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import AllTime from '../components/AllTime';
import Month from '../components/Month';
import Week from '../components/Week';
import Year from '../components/Year';

const Grid = ({ event, time }) => {
  const date = new Date();
  const year = date.getFullYear();

  if (time === 'Week') return <Week event={event} />;
  if (time === 'Month') return <Month event={event} year={year} />;
  if (time === 'Year') return <Year event={event} year={year} />;
  return <AllTime event={event} year={year} />;
};

Grid.propTypes = {
  event: PropTypes.object.isRequired,
  time: PropTypes.string.isRequired,
};

const mapStateToProps = ({ time }) => ({ time });

export default connect(mapStateToProps)(Grid);

// src/components/TimeStats.js

import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import Box from './Box';
import { types } from '../constants';
import Text from './Text';
import {
  // getDaysSinceStart,
  getStartDateWithTime
} from '../utils/dataUtils';
import {
  dateToTitle,
  isThisWeek,
  isThisMonth,
  isThisYear,
  isLastWeek,
  isLastMonth,
  isLastYear,
  titleToDate
} from '../utils/dateUtils';
import { calculateAverage, calculatePercent, calculatePercentString } from '../utils/numberUtils';
import { didMeetGoal } from '../utils/statUtils';

const TimeStats = ({ activities, allActivities, event, search }) => {
  // const daysSinceStart = getDaysSinceStart(event, activities);

  const { type } = event;

  const isBoolean = type === types.BOOLEAN;
  const isScale = type === types.SCALE;
  const isText = type === types.TEXT;

  let thisWeekCount = 0;
  let thisMonthCount = 0;
  let thisYearCount = 0;
  let allTimeCount = 0;

  let lastWeekCount = 0;
  let lastMonthCount = 0;
  let lastYearCount = 0;

  let allTimeHitCount = 0;
  let thisWeekHitCount = 0;
  let thisMonthHitCount = 0;
  let thisYearHitCount = 0;

  let lastWeekHitCount = 0;
  let lastMonthHitCount = 0;
  let lastYearHitCount = 0;

  if (event.zeros) {
    // first date to check
    const startDate = getStartDateWithTime(event, activities);

    // last date to check
    const today = new Date();

    // dates to check
    const dateTitles = [];
    let currentDate = new Date(startDate);
    while (currentDate <= today) {
      currentDate = new Date(currentDate);
      dateTitles.push(dateToTitle(currentDate));
      currentDate = currentDate.setDate(currentDate.getDate() + 1);
    }

    // map over each day to get values
    dateTitles.forEach(dateTitle => {
      const activity = activities.find(a => a.date === dateTitle);

      let activityDate;
      let activityValue;
      if (activity && activity.value) {
        activityDate = titleToDate(activity.date);

        if (isText) {
          if (search) {
            const searchRegularExpression = new RegExp(search, 'g');
            activityValue = (activity.value.match(searchRegularExpression) || []).length;
          } else {
            activityValue = activity.value ? 1 : 0;
          }
        } else {
          activityValue = Number(activity.value);
        }
      } else {
        activityDate = titleToDate(dateTitle);
        activityValue = 0;
      }

      const hitGoal = didMeetGoal(activityValue, event);

      if (isBoolean) activityValue = Number(hitGoal);

      allTimeCount += activityValue;
      if (hitGoal) allTimeHitCount += 1;

      if (isThisWeek(activityDate)) {
        thisWeekCount += activityValue;

        if (hitGoal) thisWeekHitCount += 1;
      }

      if (isLastWeek(activityDate)) {
        lastWeekCount += activityValue;

        if (hitGoal) lastWeekHitCount += 1;
      }

      if (isThisMonth(activityDate)) {
        thisMonthCount += activityValue;

        if (hitGoal) thisMonthHitCount += 1;
      }

      if (isLastMonth(activityDate)) {
        lastMonthCount += activityValue;

        if (hitGoal) lastMonthHitCount += 1;
      }

      if (isThisYear(activityDate)) {
        thisYearCount += activityValue;

        if (hitGoal) thisYearHitCount += 1;
      }

      if (isLastYear(activityDate)) {
        lastYearCount += activityValue;

        if (hitGoal) lastYearHitCount += 1;
      }
    });
  } else {
    activities.forEach(activity => {
      if (!activity.value) return;
      const activityDate = titleToDate(activity.date);

      let activityValue;
      if (isText) {
        if (search) {
          const searchRegularExpression = new RegExp(search, 'g');
          activityValue = (activity.value.match(searchRegularExpression) || []).length;
        } else {
          activityValue = activity.value ? 1 : 0;
        }
      } else {
        activityValue = Number(activity.value);
      }

      const hitGoal = didMeetGoal(activityValue, event);

      if (isBoolean) activityValue = Number(hitGoal);

      allTimeCount += activityValue;
      if (hitGoal) allTimeHitCount += 1;

      if (isThisWeek(activityDate)) {
        thisWeekCount += activityValue;

        if (hitGoal) thisWeekHitCount += 1;
      }

      if (isLastWeek(activityDate)) {
        lastWeekCount += activityValue;

        if (hitGoal) lastWeekHitCount += 1;
      }

      if (isThisMonth(activityDate)) {
        thisMonthCount += activityValue;

        if (hitGoal) thisMonthHitCount += 1;
      }

      if (isLastMonth(activityDate)) {
        lastMonthCount += activityValue;

        if (hitGoal) lastMonthHitCount += 1;
      }

      if (isThisYear(activityDate)) {
        thisYearCount += activityValue;

        if (hitGoal) thisYearHitCount += 1;
      }

      if (isLastYear(activityDate)) {
        lastYearCount += activityValue;

        if (hitGoal) lastYearHitCount += 1;
      }
    });
  }

  let daysThisWeek = 0;
  let daysThisMonth = 0;
  let daysThisYear = 0;

  let daysAllTime = 0;
  let daysLastWeek = 0;
  let daysLastMonth = 0;
  let daysLastYear = 0;

  if (event.zeros) {
    // first date to check
    const startDate = getStartDateWithTime(event, activities);

    // last date to check
    const today = new Date();

    // dates to check
    const dateTitles = [];
    let currentDate = new Date(startDate);
    while (currentDate <= today) {
      currentDate = new Date(currentDate);
      dateTitles.push(dateToTitle(currentDate));
      currentDate = currentDate.setDate(currentDate.getDate() + 1);
    }

    // map over each day to get values
    dateTitles.forEach(dateTitle => {
      const activity = activities.find(a => a.date === dateTitle);

      let activityDate;
      if (activity && activity.value) {
        activityDate = titleToDate(activity.date);
      } else {
        activityDate = titleToDate(dateTitle);
      }

      daysAllTime += 1;

      if (isThisWeek(activityDate)) {
        daysThisWeek += 1;
      }

      if (isLastWeek(activityDate)) {
        daysLastWeek += 1;
      }

      if (isThisMonth(activityDate)) {
        daysThisMonth += 1;
      }

      if (isLastMonth(activityDate)) {
        daysLastMonth += 1;
      }

      if (isThisYear(activityDate)) {
        daysThisYear += 1;
      }

      if (isLastYear(activityDate)) {
        daysLastYear += 1;
      }
    });
  } else {
    activities.forEach(activity => {
      if (!activity.value) return;
      const activityDate = titleToDate(activity.date);

      daysAllTime += 1;

      if (isThisWeek(activityDate)) {
        daysThisWeek += 1;
      }

      if (isLastWeek(activityDate)) {
        daysLastWeek += 1;
      }

      if (isThisMonth(activityDate)) {
        daysThisMonth += 1;
      }

      if (isLastMonth(activityDate)) {
        daysLastMonth += 1;
      }

      if (isThisYear(activityDate)) {
        daysThisYear += 1;
      }

      if (isLastYear(activityDate)) {
        daysLastYear += 1;
      }
    });
  }

  return (
    <Box flexDirection="column" pb="m">
      <Box justifyContent="center" pb="m">
        <Text>This Week</Text>
      </Box>
      <Box alignItems="center" justifyContent="spaceBetween" pb="m">
        <Text>Total</Text>
        <Text textAlign="right">{thisWeekCount}</Text>
      </Box>
      {!isBoolean &&
        !isText && (
          <Box alignItems="center" justifyContent="spaceBetween" pb="m">
            <Text>Average</Text>
            <Text textAlign="right">{calculateAverage(thisWeekCount, daysThisWeek)}</Text>
          </Box>
        )}
      {isText && (
        <Box alignItems="center" justifyContent="spaceBetween" pb="m">
          <Text>Percent</Text>
          <Text textAlign="right">{calculatePercentString(thisWeekCount, daysThisWeek)}</Text>
        </Box>
      )}
      {!isScale &&
        !isText && (
          <Fragment>
            {!isBoolean &&
              event.goal && (
                <Box alignItems="center" justifyContent="spaceBetween" pb="m">
                  <Text flexShrink={0}>Days Hit Goal</Text>
                  <Text textAlign="right">{thisWeekHitCount}</Text>
                </Box>
              )}
            <Box alignItems="center" justifyContent="spaceBetween" pb="m">
              <Text>Percent</Text>
              <Text textAlign="right">
                {calculatePercentString(thisWeekHitCount, daysThisWeek)}
              </Text>
            </Box>
          </Fragment>
        )}
      <Box justifyContent="center" pb="m">
        <Text>Last Week</Text>
      </Box>
      <Box alignItems="center" justifyContent="spaceBetween" pb="m">
        <Text>Total</Text>
        <Text textAlign="right">{lastWeekCount}</Text>
      </Box>
      {!isBoolean &&
        !isText && (
          <Box alignItems="center" justifyContent="spaceBetween" pb="m">
            <Text>Average</Text>
            <Text textAlign="right">{calculateAverage(lastWeekCount, daysLastWeek)}</Text>
          </Box>
        )}
      {isText && (
        <Box alignItems="center" justifyContent="spaceBetween" pb="m">
          <Text>Percent</Text>
          <Text textAlign="right">{calculatePercentString(lastWeekCount, daysLastWeek)}</Text>
        </Box>
      )}
      {!isScale &&
        !isText && (
          <Fragment>
            {!isBoolean &&
              event.goal && (
                <Box alignItems="center" justifyContent="spaceBetween" pb="m">
                  <Text flexShrink={0}>Days Hit Goal</Text>
                  <Text textAlign="right">{lastWeekHitCount}</Text>
                </Box>
              )}
            <Box alignItems="center" justifyContent="spaceBetween" pb="m">
              <Text>Percent</Text>
              <Text textAlign="right">
                {calculatePercentString(lastWeekHitCount, daysLastWeek)}
              </Text>
            </Box>
          </Fragment>
        )}
      <Box justifyContent="center" pb="m">
        <Text>This Week vs Last Week</Text>
      </Box>
      {!isBoolean &&
        !isText && (
          <Box alignItems="center" justifyContent="spaceBetween" pb="m">
            <Text>Average</Text>
            <Text textAlign="right">
              {Math.round(
                (calculateAverage(thisWeekCount, daysThisWeek) -
                  calculateAverage(lastWeekCount, daysLastWeek)) *
                  100
              ) /
                100 >=
                0 && '+'}
              {Math.round(
                (calculateAverage(thisWeekCount, daysThisWeek) -
                  calculateAverage(lastWeekCount, daysLastWeek)) *
                  100
              ) / 100}
            </Text>
          </Box>
        )}
      {isText && (
        <Box alignItems="center" justifyContent="spaceBetween" pb="m">
          <Text>Percent</Text>
          <Text textAlign="right">
            {calculatePercent(thisWeekCount, daysThisWeek) -
              calculatePercent(lastWeekCount, daysLastWeek) >=
              0 && '+'}
            {`${calculatePercent(thisWeekCount, daysThisWeek) -
              calculatePercent(lastWeekCount, daysLastWeek)}%`}
          </Text>
        </Box>
      )}
      {!isScale &&
        !isText && (
          <Box alignItems="center" justifyContent="spaceBetween" pb="m">
            <Text>Percent</Text>
            <Text textAlign="right">
              {calculatePercent(thisWeekHitCount, daysThisWeek) -
                calculatePercent(lastWeekHitCount, daysLastWeek) >=
                0 && '+'}
              {`${calculatePercent(thisWeekHitCount, daysThisWeek) -
                calculatePercent(lastWeekHitCount, daysLastWeek)}%`}
            </Text>
          </Box>
        )}
      <Box justifyContent="center" pb="m">
        <Text>This Month</Text>
      </Box>
      <Box alignItems="center" justifyContent="spaceBetween" pb="m">
        <Text>Total</Text>
        <Text textAlign="right">{thisMonthCount}</Text>
      </Box>
      {!isBoolean &&
        !isText && (
          <Box alignItems="center" justifyContent="spaceBetween" pb="m">
            <Text>Average</Text>
            <Text textAlign="right">{calculateAverage(thisMonthCount, daysThisMonth)}</Text>
          </Box>
        )}
      {isText && (
        <Box alignItems="center" justifyContent="spaceBetween" pb="m">
          <Text>Percent</Text>
          <Text textAlign="right">{calculatePercentString(thisMonthCount, daysThisMonth)}</Text>
        </Box>
      )}
      {!isScale &&
        !isText && (
          <Fragment>
            {!isBoolean &&
              event.goal && (
                <Box alignItems="center" justifyContent="spaceBetween" pb="m">
                  <Text flexShrink={0}>Days Hit Goal</Text>
                  <Text textAlign="right">{thisMonthHitCount}</Text>
                </Box>
              )}
            <Box alignItems="center" justifyContent="spaceBetween" pb="m">
              <Text>Percent</Text>
              <Text textAlign="right">
                {calculatePercentString(thisMonthHitCount, daysThisMonth)}
              </Text>
            </Box>
          </Fragment>
        )}
      <Box justifyContent="center" pb="m">
        <Text>Last Month</Text>
      </Box>
      <Box alignItems="center" justifyContent="spaceBetween" pb="m">
        <Text>Total</Text>
        <Text textAlign="right">{lastMonthCount}</Text>
      </Box>
      {!isBoolean &&
        !isText && (
          <Box alignItems="center" justifyContent="spaceBetween" pb="m">
            <Text>Average</Text>
            <Text textAlign="right">{calculateAverage(lastMonthCount, daysLastMonth)}</Text>
          </Box>
        )}
      {isText && (
        <Box alignItems="center" justifyContent="spaceBetween" pb="m">
          <Text>Percent</Text>
          <Text textAlign="right">{calculatePercentString(lastMonthCount, daysLastMonth)}</Text>
        </Box>
      )}
      {!isScale &&
        !isText && (
          <Fragment>
            {!isBoolean &&
              event.goal && (
                <Box alignItems="center" justifyContent="spaceBetween" pb="m">
                  <Text flexShrink={0}>Days Hit Goal</Text>
                  <Text textAlign="right">{lastMonthHitCount}</Text>
                </Box>
              )}
            <Box alignItems="center" justifyContent="spaceBetween" pb="m">
              <Text>Percent</Text>
              <Text textAlign="right">
                {calculatePercentString(lastMonthHitCount, daysLastMonth)}
              </Text>
            </Box>
          </Fragment>
        )}
      <Box justifyContent="center" pb="m">
        <Text>This Month vs Last Month</Text>
      </Box>
      {!isBoolean &&
        !isText && (
          <Box alignItems="center" justifyContent="spaceBetween" pb="m">
            <Text>Average</Text>
            <Text textAlign="right">
              {Math.round(
                (calculateAverage(thisMonthCount, daysThisMonth) -
                  calculateAverage(lastMonthCount, daysLastMonth)) *
                  100
              ) /
                100 >=
                0 && '+'}
              {Math.round(
                (calculateAverage(thisMonthCount, daysThisMonth) -
                  calculateAverage(lastMonthCount, daysLastMonth)) *
                  100
              ) / 100}
            </Text>
          </Box>
        )}
      {isText && (
        <Box alignItems="center" justifyContent="spaceBetween" pb="m">
          <Text>Percent</Text>
          <Text textAlign="right">
            {calculatePercent(thisMonthCount, daysThisMonth) -
              calculatePercent(lastMonthCount, daysLastMonth) >=
              0 && '+'}
            {`${calculatePercent(thisMonthCount, daysThisMonth) -
              calculatePercent(lastMonthCount, daysLastMonth)}%`}
          </Text>
        </Box>
      )}
      {!isScale &&
        !isText && (
          <Box alignItems="center" justifyContent="spaceBetween" pb="m">
            <Text>Percent</Text>
            <Text textAlign="right">
              {calculatePercent(thisMonthHitCount, daysThisMonth) -
                calculatePercent(lastMonthHitCount, daysLastMonth) >=
                0 && '+'}
              {`${calculatePercent(thisMonthHitCount, daysThisMonth) -
                calculatePercent(lastMonthHitCount, daysLastMonth)}%`}
            </Text>
          </Box>
        )}
      <Box justifyContent="center" pb="m">
        <Text>This Year</Text>
      </Box>
      <Box alignItems="center" justifyContent="spaceBetween" pb="m">
        <Text>Total</Text>
        <Text textAlign="right">{thisYearCount}</Text>
      </Box>
      {!isBoolean &&
        !isText && (
          <Box alignItems="center" justifyContent="spaceBetween" pb="m">
            <Text>Average</Text>
            <Text textAlign="right">{calculateAverage(thisYearCount, daysThisYear)}</Text>
          </Box>
        )}
      {isText && (
        <Box alignItems="center" justifyContent="spaceBetween" pb="m">
          <Text>Percent</Text>
          <Text textAlign="right">{calculatePercentString(thisYearCount, daysThisYear)}</Text>
        </Box>
      )}
      {!isScale &&
        !isText && (
          <Fragment>
            {!isBoolean &&
              event.goal && (
                <Box alignItems="center" justifyContent="spaceBetween" pb="m">
                  <Text flexShrink={0}>Days Hit Goal</Text>
                  <Text textAlign="right">{thisYearHitCount}</Text>
                </Box>
              )}
            <Box alignItems="center" justifyContent="spaceBetween" pb="m">
              <Text>Percent</Text>
              <Text textAlign="right">
                {calculatePercentString(thisYearHitCount, daysThisYear)}
              </Text>
            </Box>
          </Fragment>
        )}
      <Box justifyContent="center" pb="m">
        <Text>Last Year</Text>
      </Box>
      <Box alignItems="center" justifyContent="spaceBetween" pb="m">
        <Text>Total</Text>
        <Text textAlign="right">{lastYearCount}</Text>
      </Box>
      {!isBoolean &&
        !isText && (
          <Box alignItems="center" justifyContent="spaceBetween" pb="m">
            <Text>Average</Text>
            <Text textAlign="right">{calculateAverage(lastYearCount, daysLastYear)}</Text>
          </Box>
        )}
      {isText && (
        <Box alignItems="center" justifyContent="spaceBetween" pb="m">
          <Text>Percent</Text>
          <Text textAlign="right">{calculatePercentString(lastYearCount, daysLastYear)}</Text>
        </Box>
      )}
      {!isScale &&
        !isText && (
          <Fragment>
            {!isBoolean &&
              event.goal && (
                <Box alignItems="center" justifyContent="spaceBetween" pb="m">
                  <Text flexShrink={0}>Days Hit Goal</Text>
                  <Text textAlign="right">{lastYearHitCount}</Text>
                </Box>
              )}
            <Box alignItems="center" justifyContent="spaceBetween" pb="m">
              <Text>Percent</Text>
              <Text textAlign="right">
                {calculatePercentString(lastYearHitCount, daysLastYear)}
              </Text>
            </Box>
          </Fragment>
        )}
      <Box justifyContent="center" pb="m">
        <Text>This Year vs Last Year</Text>
      </Box>
      {!isBoolean &&
        !isText && (
          <Box alignItems="center" justifyContent="spaceBetween" pb="m">
            <Text>Average</Text>
            <Text textAlign="right">
              {Math.round(
                (calculateAverage(thisYearCount, daysThisYear) -
                  calculateAverage(lastYearCount, daysLastYear)) *
                  100
              ) /
                100 >=
                0 && '+'}
              {Math.round(
                (calculateAverage(thisYearCount, daysThisYear) -
                  calculateAverage(lastYearCount, daysLastYear)) *
                  100
              ) / 100}
            </Text>
          </Box>
        )}
      {isText && (
        <Box alignItems="center" justifyContent="spaceBetween" pb="m">
          <Text>Percent</Text>
          <Text textAlign="right">
            {calculatePercent(thisYearCount, daysThisYear) -
              calculatePercent(lastYearCount, daysLastYear) >=
              0 && '+'}
            {`${calculatePercent(thisYearCount, daysThisYear) -
              calculatePercent(lastYearCount, daysLastYear)}%`}
          </Text>
        </Box>
      )}
      {!isScale &&
        !isText && (
          <Box alignItems="center" justifyContent="spaceBetween" pb="m">
            <Text>Percent</Text>
            <Text textAlign="right">
              {calculatePercent(thisYearHitCount, daysThisYear) -
                calculatePercent(lastYearHitCount, daysLastYear) >=
                0 && '+'}
              {`${calculatePercent(thisYearHitCount, daysThisYear) -
                calculatePercent(lastYearHitCount, daysLastYear)}%`}
            </Text>
          </Box>
        )}
      <Box justifyContent="center" pb="m">
        <Text>All-Time</Text>
      </Box>
      <Box alignItems="center" justifyContent="spaceBetween" pb="m">
        <Text>Total</Text>
        <Text textAlign="right">{allTimeCount}</Text>
      </Box>
      {!isBoolean &&
        !isText && (
          <Box alignItems="center" justifyContent="spaceBetween" pb="m">
            <Text>Average</Text>
            <Text textAlign="right">{calculateAverage(allTimeCount, daysAllTime)}</Text>
          </Box>
        )}
      {isText && (
        <Box alignItems="center" justifyContent="spaceBetween" pb="m">
          <Text>Percent</Text>
          <Text textAlign="right">{calculatePercentString(allTimeCount, daysAllTime)}</Text>
        </Box>
      )}
      {!isScale &&
        !isText && (
          <Fragment>
            {!isBoolean &&
              event.goal && (
                <Box alignItems="center" justifyContent="spaceBetween" pb="m">
                  <Text flexShrink={0}>Days Hit Goal</Text>
                  <Text textAlign="right">{allTimeHitCount}</Text>
                </Box>
              )}
            <Box alignItems="center" justifyContent="spaceBetween" pb="m">
              <Text>Percent</Text>
              <Text textAlign="right">{calculatePercentString(allTimeHitCount, daysAllTime)}</Text>
            </Box>
          </Fragment>
        )}
    </Box>
  );
};

TimeStats.propTypes = {
  activities: PropTypes.array.isRequired,
  event: PropTypes.object.isRequired,
  search: PropTypes.string,
};

export default TimeStats;

// import ES6Promise from 'es6-promise';
// ES6Promise.polyfill();
import axios from 'axios';

import ActionTypes from './actionTypes';
import { loadActivities } from './activityActions';
import { loadEvents } from './eventActions';

export const changeCode = code => dispatch => dispatch({ type: ActionTypes.CHANGE_CODE, code });
export const changeEmail = email => dispatch => dispatch({ type: ActionTypes.CHANGE_EMAIL, email });
export const changeName = name => dispatch => dispatch({ type: ActionTypes.CHANGE_NAME, name });
export const changePassword = password => dispatch =>
  dispatch({ type: ActionTypes.CHANGE_PASSWORD, password });

// create user
export const createUser = user => dispatch => {
  dispatch({ type: ActionTypes.CREATE_USER_REQUEST, user }); // send create user request

  return axios.post('/api/user', { user }).then(
    () => null,
    () => dispatch({ type: ActionTypes.CREATE_USER_FAIL, error: 'Sorry, no internet connection' }) // create user failed
  );
};

// sign up user
export const signupUser = user => dispatch => {
  dispatch({ type: ActionTypes.REQUEST });

  return axios
    .post('/api/user/signup', { user })
    .then(
      () => dispatch({ type: ActionTypes.SIGNUP_USER, user }),
      () => dispatch({ type: ActionTypes.ERROR, error: 'Sorry, name or email is taken' })
    );
};

// login user
export const loginUser = user => dispatch => {
  dispatch({ type: ActionTypes.REQUEST });

  return axios.post('/api/user/login', { user }).then(
    response => {
      dispatch({ type: ActionTypes.LOGIN_USER, user: response.data.user });
      dispatch(loadActivities(response.data.user._id));
      dispatch(loadEvents(response.data.user._id));
    },
    () => {
      dispatch({ type: ActionTypes.ERROR, error: 'Sorry, password does not match' });
    }
  );
};

export const logout = () => dispatch => dispatch({ type: ActionTypes.LOGOUT });

export const emailCode = email => dispatch => {
  dispatch({ type: ActionTypes.REQUEST });

  axios.post('/api/user/code', { email }).then(
    response => dispatch({ type: ActionTypes.EMAIL_CODE_SUCCESS, ...response.data }),
    () =>
      dispatch({
        type: ActionTypes.ERROR,
        error: 'Email account not found'
      })
  );
};

export const resetPassword = (code, password) => dispatch => {
  dispatch({ type: ActionTypes.REQUEST });

  axios.post('/api/user/resetPassword', { code, password }).then(
    response => dispatch({ type: ActionTypes.RESET_PASSWORD_SUCCESS, ...response.data }),
    () =>
      dispatch({
        type: ActionTypes.ERROR,
        error: 'Code does not match'
      })
  );
};

// src/views/IdeasView.js

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import * as eventActions from '../actions/eventActions';
import BackButton from '../components/BackButton';
import Body from '../components/Body';
import Button from '../components/Button';
import Header from '../components/Header';
import HeaderButton from '../components/HeaderButton';
import Title from '../components/Title';
import View from '../components/View';
import { types, values } from '../constants';

const ideas = [
  {
    name: 'Alcohol',
    goal: values.ZERO,
    type: types.BOOLEAN
  },
  {
    name: 'Ate Healthy',
    goal: values.ONE,
    type: types.BOOLEAN
  },
  {
    name: 'Caffeine',
    goal: values.ZERO,
    type: types.BOOLEAN
  },
  {
    name: 'Candy',
    goal: values.ZERO,
    type: types.BOOLEAN
  },
  {
    name: 'Coffee',
    type: types.COUNT
  },
  {
    name: 'Create',
    goal: values.ONE,
    type: types.BOOLEAN
  },
  {
    name: 'Dairy',
    goal: values.ZERO,
    type: types.BOOLEAN
  },
  {
    name: 'Drugs',
    goal: values.ZERO,
    type: types.BOOLEAN
  },
  {
    name: 'Exercise',
    goal: values.ONE,
    type: types.BOOLEAN
  },
  {
    name: 'Fast Food',
    goal: values.ZERO,
    type: types.BOOLEAN
  },
  {
    name: 'Felt',
    type: types.SCALE
  },
  {
    name: 'Floss Teeth',
    goal: values.ONE,
    type: types.BOOLEAN
  },
  {
    name: 'Gluten',
    goal: values.ZERO,
    type: types.BOOLEAN
  },
  {
    name: 'Grain',
    goal: values.ZERO,
    type: types.BOOLEAN
  },
  {
    name: 'Gratitude',
    goal: values.ONE,
    type: types.BOOLEAN
  },
  {
    name: 'Gym',
    goal: values.ONE,
    type: types.BOOLEAN
  },
  {
    name: 'Helped someone',
    goal: values.ONE,
    type: types.BOOLEAN
  },
  {
    name: 'Journal',
    goal: values.ONE,
    type: types.BOOLEAN
  },
  {
    name: 'Juul',
    goal: values.ZERO,
    type: types.BOOLEAN
  },
  {
    name: 'Learned something new',
    goal: values.ONE,
    type: types.BOOLEAN
  },
  {
    name: 'Meat',
    goal: values.ZERO,
    type: types.BOOLEAN
  },
  {
    name: 'Meditate',
    goal: values.ONE,
    type: types.BOOLEAN
  },
  {
    name: 'Overate',
    goal: values.ZERO,
    type: types.BOOLEAN
  },
  {
    name: 'Porn',
    goal: values.ZERO,
    type: types.BOOLEAN
  },
  {
    name: 'Push ups',
    type: types.COUNT
  },
  {
    name: 'Read',
    goal: values.ONE,
    type: types.BOOLEAN
  },
  {
    name: 'Salad',
    goal: values.ONE,
    type: types.BOOLEAN
  },
  {
    name: 'Sex',
    type: types.COUNT
  },
  {
    name: 'Sit ups',
    goal: values.ONE,
    type: types.BOOLEAN
  },
  {
    name: 'Sleep 8 hours',
    goal: values.ONE,
    type: types.BOOLEAN
  },
  {
    name: 'Smoke',
    goal: values.ZERO,
    type: types.BOOLEAN
  },
  {
    name: 'Soda',
    goal: values.ZERO,
    type: types.BOOLEAN
  },
  {
    name: 'Sugar',
    goal: values.ZERO,
    type: types.BOOLEAN
  },
  {
    name: 'Talked to',
    type: types.TEXT
  },
  {
    name: 'TV',
    goal: values.ZERO,
    type: types.BOOLEAN
  },
  {
    name: 'Video Games',
    goal: values.ZERO,
    type: types.BOOLEAN
  },
  {
    name: 'Vitamins',
    goal: values.ONE,
    type: types.BOOLEAN
  },
  {
    name: 'Walk',
    type: types.COUNT
  },
  {
    name: 'Water',
    type: types.COUNT
  },
  {
    name: 'Went to bed early',
    goal: values.ONE,
    type: types.BOOLEAN
  },
  {
    name: 'Woke up early',
    goal: values.ONE,
    type: types.BOOLEAN
  },
  {
    name: 'Work',
    goal: values.ONE,
    type: types.BOOLEAN
  },
  {
    name: 'Workout',
    goal: values.ONE,
    type: types.BOOLEAN
  },
  {
    name: 'Write',
    goal: values.ONE,
    type: types.BOOLEAN
  },
  {
    name: 'Yoga',
    goal: values.ONE,
    type: types.BOOLEAN
  }
];

class IdeasView extends Component {
  constructor(props) {
    super(props);

    this.changeEvent = this.changeEvent.bind(this);
  }

  changeEvent(event) {
    const { changeEvent, history } = this.props;

    changeEvent(event);

    history.goBack();
  }

  render() {
    return (
      <View>
        <Helmet>
          <title>Activity Ideas</title>
          <meta name="description" content="Top daily activity ideas" />
        </Helmet>
        <Header>
          <BackButton />
          <Title>Activity Ideas</Title>
          <HeaderButton hide />
        </Header>
        <Body p="m m n">
          {ideas.map(idea => (
            <Button key={idea.name} mb="m" onClick={() => this.changeEvent(idea)} width="full">
              {idea.name}
            </Button>
          ))}
        </Body>
      </View>
    );
  }
}

IdeasView.propTypes = {
  changeEvent: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

export default connect(null, {
  ...eventActions
})(IdeasView);

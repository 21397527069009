// src/views/HowToVideosView.js

import React from 'react';
import { Helmet } from 'react-helmet';

import BackButton from '../components/BackButton';
import Body from '../components/Body';
import Header from '../components/Header';
import HeaderButton from '../components/HeaderButton';
import Title from '../components/Title';
import View from '../components/View';

const HowToVideosView = () => (
  <View>
    <Helmet>
      <title>How To Videos</title>
      <meta name="description" content="Videos on how to use Aware" />
    </Helmet>
    <Header>
      <BackButton />
      <Title>How To Videos</Title>
      <HeaderButton hide />
    </Header>
    <Body p="m" alignItems="center">
      <Title pb="m">What is Aware?</Title>
      <iframe
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        frameBorder="0"
        height="315"
        src="https://www.youtube.com/embed/ucyWKZtKWZM"
        title="What is Aware?"
        width="560"
      />
      <Title pb="m" pt="l">
        Why Use Aware?
      </Title>
      <iframe
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        frameBorder="0"
        height="315"
        src="https://www.youtube.com/embed/YfLd_ZniOtU"
        title="How To Use Aware"
        width="560"
      />
      <Title pb="m" pt="l">
        How To Edit An Activity
      </Title>
      <iframe
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        frameBorder="0"
        height="315"
        src="https://www.youtube.com/embed/Vcs7hEDGYJI"
        title="How To Edit An Activity"
        width="560"
      />
      <Title pb="m" pt="l">
        How To Use Aware
      </Title>
      <iframe
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        frameBorder="0"
        height="315"
        src="https://www.youtube.com/embed/8yH0aD1mr8U"
        title="How To Use Aware"
        width="560"
      />
    </Body>
  </View>
);

export default HowToVideosView;

// src/components/Icon/iconTypes.js

import {
  FiLogIn as login,
  FiLogOut as logout,
} from 'react-icons/fi';
import {
  IoMdArrowRoundBack as back,
  IoMdCalendar as calendar,
  IoMdTrendingUp as chart,
  IoMdDownload as download,
  IoMdArrowRoundForward as forward,
  IoMdEyeOff as hide,
  IoIosStar as star,
  IoMdTrash as trash,
  IoMdVideocam as video,
} from 'react-icons/io';
import {
  MdAdd as add,
  MdCheckBox as check,
  MdCheckBoxOutlineBlank as checkbox,
  MdClose as close,
  MdRemove as dash,
  MdAttachMoney as donate,
  MdEdit as edit,
  MdEmail as email,
  MdGridOn as grid,
  MdReorder as reorder,
  MdMenu as sideMenu,
  MdPanoramaFishEye as select,
  MdLens as unselect,
} from 'react-icons/md';

export default {
  add,
  back,
  calendar,
  chart,
  check,
  checkbox,
  close,
  dash,
  donate,
  download,
  edit,
  email,
  forward,
  grid,
  hide,
  login,
  logout,
  reorder,
  select,
  sideMenu,
  star,
  trash,
  unselect,
  video
};

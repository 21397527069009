// src/reducers/initialState.js

import { getTodaysTitle } from '../utils/dateUtils';

export default {
  activities: [],
  activity: {
    value: '',
  },
  chart: 'Grid',
  day: getTodaysTitle(),
  editEvent: {
    name: '',
    type: 'count',
    goal: '',
    units: '',
    color: '',
    zeros: false,
  },
  editUser: {},
  error: '',
  event: {},
  events: [],
  loading: false,
  loadingAll: false,
  newEvent: {
    name: '',
    type: 'count',
    goal: '',
    units: '',
    color: '',
    zeros: false,
  },
  search: '',
  sideMenu: false,
  stats: {
    users: [],
    events: [],
    activities: []
  },
  time: 'Week',
  user: {}
};

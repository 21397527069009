import pluralize from 'pluralize';

import { colors, colorToRGB, goalValues, times, types, values } from '../constants';
import {
  calendarDateToTitle,
  dateToTitle,
  daysBetweenToday,
  getFirstDayOfMonth,
  getFirstDayOfWeek,
  getFirstDayOfYear,
  isThisMonth,
  isThisWeek,
  isThisYear,
  isYear,
  titleToDate
} from './dateUtils';
import { sortByDate } from './sortUtils';
import { capitalizeFirstLetter } from './stringUtils';

export const getCalendarClass = (data, event) => {
  const { goal, type } = event;
  const { count, value } = data;

  let className = '';
  let temp = '';

  if (!data || (type !== types.BOOLEAN && (!count && !value))) {
    return `${className}color-empty`;
  }

  switch (type) {
    case types.BOOLEAN:
      if ((goal === '1' && count >= 1) || (goal === values.ZERO && count === 0 && value)) {
        temp += 'yes';
      } else if (count && !goal) {
        temp += 'yes';
      } else if (value && goal) {
        temp += 'no';
      } else {
        temp += 'empty';
      }
      break;
    case types.COUNT:
    case types.SCALE: {
      const val = Math.round(count / goal * 4);
      if (goal) temp += `scale-${val > 4 ? 4 : val}`;
      else temp += `scale-${count > 4 ? 4 : count}`;
      if (!count) temp = 'empty';
      break;
    }
    default:
      temp += value ? 'scale-4' : 'empty';
  }

  return `${className}color-${temp}`;
};

export const getEventActivities = (event, activities) =>
  activities.filter(a => a.event_id === event._id); // && a.value);

export const getEventActivitiesForTime = (event, activities, time) => {
  const eventActivities = getEventActivities(event, activities);

  if (time === times.WEEK) {
    return getThisWeekActivities(eventActivities);
  } else if (time === times.MONTH) {
    return getThisMonthActivities(eventActivities);
  } else if (time === times.YEAR) {
    return getThisYearActivities(eventActivities);
  }

  return eventActivities;
};

export const getEventActivitiesForYear = (event, activities, year) => {
  const eventActivities = getEventActivities(event, activities);
  return eventActivities.filter(a => isYear(titleToDate(a.date), year));
};

export const getEventActivitiesValues = (event, eventActivities, time) => {
  const { type, zeros } = event;

  const eventActivitiesValues = {};

  if (zeros) {
    // first date to check
    const startDate = getStartDateWithTime(event, eventActivities, time);

    // last date to check
    const today = new Date();

    // dates to check
    const dateTitles = [];
    let currentDate = new Date(startDate);
    while (currentDate <= today) {
      currentDate = new Date(currentDate);
      dateTitles.push(dateToTitle(currentDate));
      currentDate = currentDate.setDate(currentDate.getDate() + 1);
    }

    // map over each day to get values
    dateTitles.forEach(dateTitle => {
      const activity = eventActivities.find(a => a.date === dateTitle);
      if (!activity || !activity.value) {
        let zeroKey = '0';
        if (type === types.TEXT) {
          zeroKey = 'Empty';
        } else if (type === types.BOOLEAN) {
          zeroKey = values.NO;
        }
        eventActivitiesValues[zeroKey] = (eventActivitiesValues[zeroKey] || 0) + 1;
      } else {
        const { value } = activity;
        if (type === types.TEXT) {
          const vals = value
            .split('. ')
            .join(', ')
            .split(', ');
          vals.forEach(val => {
            const newVal = capitalizeFirstLetter(pluralize.singular(val)).trim();
            eventActivitiesValues[newVal] = (eventActivitiesValues[newVal] || 0) + 1;
          });
        } else if (type === types.BOOLEAN) {
          const val = value === values.ONE ? values.YES : values.NO;
          eventActivitiesValues[val] = (eventActivitiesValues[val] || 0) + 1;
        } else {
          eventActivitiesValues[value] = (eventActivitiesValues[value] || 0) + 1;
        }
      }
    });
  } else {
    eventActivities.forEach(({ value }) => {
      if (!value) return;

      if (type === types.TEXT) {
        const vals = value
          .split('. ')
          .join(', ')
          .split(', ');
        vals.forEach(val => {
          const newVal = capitalizeFirstLetter(pluralize.singular(val)).trim();
          eventActivitiesValues[newVal] = (eventActivitiesValues[newVal] || 0) + 1;
        });
      } else if (type === types.BOOLEAN) {
        const val = value === values.ONE ? values.YES : values.NO;
        eventActivitiesValues[val] = (eventActivitiesValues[val] || 0) + 1;
      } else {
        eventActivitiesValues[value] = (eventActivitiesValues[value] || 0) + 1;
      }
    });
  }

  return eventActivitiesValues;
};

export const getEventActivitiesValuesSearched = (event, eventActivities, search, time) => {
  const values = getEventActivitiesValues(event, eventActivities, time);

  if (!search) return values;
  if (event.type !== types.TEXT) return values;

  const lowerCaseSearch = pluralize.singular(search.toLowerCase());
  Object.keys(values).forEach(key => {
    if (!key.toLowerCase().includes(lowerCaseSearch)) delete values[key];
  });

  return values;
};

export const getEventActivitiesSearched = (eventActivities, search) => {
  if (!search) return eventActivities;

  const lowerCaseSearch = pluralize.singular(search.toLowerCase());

  return eventActivities.filter(({ value }) => {
    if (!value) return false;

    const vals = value
      .split('. ')
      .join(', ')
      .split(', ');
    return vals.some(val => val.toLowerCase().includes(lowerCaseSearch));
  });
};

export const getEventActivitiesGoals = ({ goal, type }, eventActivities) => {
  const goals = {};

  eventActivities.forEach(({ value }) => {
    if (type === types.COUNT && goal) {
      const val = Number(value) >= Number(goal) ? goalValues.HIT : goalValues.MISS;
      goals[val] = (goals[val] || 0) + 1;
    }
  });

  return goals;
};

export const getEventRGBAColor = (event, a) => {
  if (event.color) {
    return `rgba(${colorToRGB[event.color].join(', ')}, ${a})`;
  } else {
    return `rgba(123, 201, 111, ${a})`;
  }
};

export const getPieColor = (event, data, high) =>
  data.map(value => {
    if (event.goal && value[0] === values.NO && event.goal === values.ZERO) return colors.green;
    else if (value[0] === values.NO) return colors.red;
    else if (event.goal && value[0] === values.YES && event.goal === values.ZERO) return colors.red;
    else if (value[0] === values.YES) return colors.green;
    else if (value[0] === goalValues.HIT) return colors.green;
    else if (value[0] === goalValues.MISS) return colors.red;
    return getEventRGBAColor(event, value[1] / high);
  });

export const getTooltip = (data, event) => {
  const date = calendarDateToTitle(data.date);

  // event goal, type, and units
  const { goal, type, units } = event;

  // is event type a boolean
  const isBoolean = type === types.BOOLEAN;

  // no count, and count is not not a number, return date
  if (!data.count && !isNaN(data.count) && !isBoolean) {
    return { 'data-tip': date };
  }

  let value = '';

  // count is not a number, use value
  if (isNaN(data.count)) value += data.value;

  if (isBoolean) {
    // event is boolean, base text on value
    value += data.value === '1' ? 'Yes' : 'No';
  } else if (type !== types.TEXT) {
    // display count
    value += data.count;
  }

  // has goal and event type is not boolean or scale
  if (goal && type !== types.BOOLEAN && type !== types.SCALE) {
    value += ` / ${goal} (${Math.round(Number(data.value || 0) / Number(goal) * 100)}%)`;
  }

  // event has units
  if (units) value += ` ${units}`;

  // return tooltip
  return { 'data-tip': `${value} on ${date}` };
};

export const getThisMonthActivities = activities =>
  activities.filter(a => isThisMonth(titleToDate(a.date)));
export const getThisWeekActivities = activities =>
  activities.filter(a => isThisWeek(titleToDate(a.date)));
export const getThisYearActivities = activities =>
  activities.filter(a => isThisYear(titleToDate(a.date)));

export const getStartDate = (event, activities) => {
  const eventActivities = activities.sort(sortByDate);
  const eventCreatedDate = new Date(event.created);
  if (!eventActivities.length) return eventCreatedDate;
  const firstActivityDate = new Date(titleToDate(eventActivities[0].date));
  return eventCreatedDate > firstActivityDate ? firstActivityDate : eventCreatedDate;
};

export const getDaysSinceStart = (event, activities) => {
  const startDate = getStartDate(event, activities);
  return daysBetweenToday(startDate);
};

export const getStartDateWithTime = (event, activities, time) => {
  if (time === times.WEEK) {
    return getFirstDayOfWeek();
  } else if (time === times.MONTH) {
    return getFirstDayOfMonth();
  } else if (time === times.YEAR) {
    return getFirstDayOfYear();
  }
  return getStartDate(event, activities);
}

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import * as errorActions from '../actions/errorActions';
import * as userActions from '../actions/userActions';
import BackButton from '../components/BackButton';
import Body from '../components/Body';
import Box from '../components/Box';
import Button from '../components/Button';
import Error from '../components/Error';
import Form from '../components/Form';
import Header from '../components/Header';
import Input from '../components/Input';
import Label from '../components/Label';
import Text from '../components/Text';
import View from '../components/View';
import { checkEmail } from '../utils/stringUtils';

const handlePrivacy = () => {
  window.open('https://www.awarepath.com/privacy', '_blank');
};

const handleTerms = () => {
  window.open('https://www.awarepath.com/terms', '_blank');
};

class SignupView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: '',
      login: false
    };

    this.clearError = this.clearError.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    if (this.props.user.name) {
      this.props.history.replace('/');
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.user.name) {
      this.props.history.push('/');
    }
  }

  clearError() {
    const { clearError, error: err } = this.props;

    if (err) clearError();
    this.setState({ error: '' });
  }

  handleSubmit(e) {
    e.preventDefault();
    const { clearError, editUser, user, loginUser, signupUser } = this.props;
    const { login } = this.state;

    clearError();
    this.setState({ error: '' });

    if (!editUser.name) {
      this.setState({ error: 'Please enter your name' });
      return;
    }

    if (!login && !editUser.email) {
      this.setState({ error: 'Please enter your email' });
      return;
    }

    if (!login && !checkEmail(editUser.email)) {
      this.setState({ error: 'Please enter a valid email' });
      return;
    }

    if (!editUser.password) {
      this.setState({ error: 'Please enter your password' });
      return;
    }

    editUser._id = user._id;

    if (login) {
      loginUser(editUser);
    } else {
      signupUser(editUser);
    }
  }

  render() {
    const { changeEmail, changeName, changePassword, editUser, error: err, loading } = this.props;
    const { error, login } = this.state;
    const loginButton = loading ? 'Loading...' : login ? 'Sign in' : 'Sign up';
    const switchButton = login ? 'Sign up' : 'Sign in';
    const helperText = login ? 'Need an account?' : 'Have an account?';

    return (
      <View>
        <Header containerId="signup">
          <BackButton />
        </Header>
        <Body id="signup" p="n m">
          <Form onSubmit={this.handleSubmit}>
            {!login && <Label>Name</Label>}
            {!login && (
              <Input
                autoFocus
                onChange={e => {
                  this.clearError();
                  changeName(e.target.value);
                }}
                placeholder="Name"
                type="text"
                value={editUser.name}
                width="full"
              />
            )}
            <Label>{!login ? 'Email' : 'Name'}</Label>
            <Input
              autoFocus={login}
              onChange={e => {
                this.clearError();
                login ? changeName(e.target.value) : changeEmail(e.target.value);
              }}
              placeholder={login ? 'Or email' : 'Email'}
              type="text"
              value={login ? editUser.name : editUser.email}
              width="full"
            />
            <Label>Password</Label>
            <Input
              onChange={e => {
                this.clearError();
                changePassword(e.target.value);
              }}
              placeholder="Password"
              type="password"
              value={editUser.password}
              width="full"
            />
            {(error || err) && <Error mt="m">{error || err}</Error>}
            <Button
              disabled={loading}
              id="loginButton"
              mt="l"
              onClick={this.handleSubmit}
              width="full"
            >
              {loginButton}
            </Button>
            <input type="submit" style={{ display: 'none' }} />
          </Form>
          {!login && (
            <Text color="grey" fontSize="s" pt="l" textAlign="center">
              {'By pressing Signup, you agree with our'}
              <Button
                cursor
                color="grey"
                fontSize="s"
                id="privacy"
                onClick={handlePrivacy}
                p="xs"
                width="auto"
                noShadow
                display="inline"
              >
                Privacy Policy
              </Button>and
              <Button
                cursor
                color="grey"
                fontSize="s"
                id="terms"
                onClick={handleTerms}
                p="xs"
                width="auto"
                noShadow
                display="inline"
              >
                Terms of Service
              </Button>
            </Text>
          )}
          <Box justifyContent="center" mt="xxxl">
            <Text color="darkGrey" textAlign="center" p="s n" width="auto">
              {helperText}
            </Text>
            <Button
              color="black"
              backgroundColor="transparent"
              onClick={() => {
                this.clearError();
                this.setState({ login: !login });
              }}
              id="switchLogin"
              noShadow
              p="s"
              width="auto"
            >
              {switchButton}
            </Button>
          </Box>
          {login && (
            <Box justifyContent="center" mt="xxxl">
              <Button
                backgroundColor="transparent"
                onClick={this.clearError}
                to="/forgotPassword"
                id="forgotPassword"
                noShadow
                p="s"
                width="auto"
              >
                Forgot password?
              </Button>
            </Box>
          )}
          <Box justifyContent="center" mt="xxl" mb="m">
            <Text color="grey" textAlign="center" p="s n" width="auto">
              Need help?
            </Text>
            <Button
              backgroundColor="transparent"
              color="darkGrey"
              href="mailto:help@awarepath.com?subject=Help"
              target="_blank"
              noShadow
              p="s"
              width="auto"
            >
              Contact us
            </Button>
          </Box>
        </Body>
      </View>
    );
  }
}

SignupView.propTypes = {
  changeName: PropTypes.func.isRequired,
  changeEmail: PropTypes.func.isRequired,
  changePassword: PropTypes.func.isRequired,
  clearError: PropTypes.func.isRequired,
  editUser: PropTypes.object.isRequired,
  error: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  loginUser: PropTypes.func.isRequired,
  signupUser: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
};

const mapStateToProps = ({ editUser, error, loading, user }) => ({
  editUser,
  error,
  loading,
  user
});

export default connect(mapStateToProps, {
  ...errorActions,
  ...userActions
})(SignupView);

// src/components/Box.js

import React from 'react';

import Clickable from './Clickable';

const Box = props => <Clickable {...props} />;

Box.defaultProps = {
  position: 'relative',
  width: 'full'
};

export default Box;

// src/containers/SideMenu.js

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as sideMenuActions from '../actions/sideMenuActions';
import * as userActions from '../actions/userActions';
import Button from '../components/Button';
import Body from '../components/Body';
import Box from '../components/Box';
import Header from '../components/Header';
import HeaderButton from '../components/HeaderButton';
import Icon from '../components/Icon';
import View from '../components/View';
import { appStore } from '../constants';
import { download } from '../utils/csvUtils';

const styles = {
  sideMenu: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    width: '100%',
    zIndex: 20,
    display: 'flex'
  }
};

const SideMenu = ({
  activities,
  events,
  history,
  logout,
  sideMenu,
  toggleSideMenu,
  user: { name }
}) =>
  sideMenu && (
    <div style={styles.sideMenu}>
      <View>
        <Header>
          <HeaderButton onClick={toggleSideMenu}>
            <Icon type="close" />
          </HeaderButton>
        </Header>
        <Body p="m">
          {!name && (
            <Button
              mb="m"
              onClick={() => {
                toggleSideMenu();
                history.push('/signup');
              }}
            >
              <Icon position="absolute" left="m" type="login" />
              Sign up / sign in
            </Button>
          )}
          {!!events.length && (
            <Button
              mb="m"
              onClick={() => {
                toggleSideMenu();
                history.push('/reorder');
              }}
            >
              <Icon position="absolute" left="m" type="reorder" />
              Reorder activities
            </Button>
          )}
          {events.some(event => !!event.hidden) && (
            <Button
              mb="m"
              onClick={() => {
                toggleSideMenu();
                history.push('/hidden');
              }}
            >
              <Icon position="absolute" left="m" type="hide" />
              Hidden activities
            </Button>
          )}
          <Button mb="m" onClick={() => download(events, activities)}>
            <Icon position="absolute" left="m" type="download" />
            Export data
          </Button>
          <Button
            mb="m"
            href="https://itunes.apple.com/us/app/streaking-daily-habit-tracker/id1462378374?mt=8"
            target="_blank"
          >
            <Icon position="absolute" left="m" type="star" />
            Rate Aware
          </Button>
          <Button
            mb="m"
            onClick={() => {
              toggleSideMenu();
              history.push('/videos');
            }}
          >
            <Icon position="absolute" left="m" type="video" />
            Videos
          </Button>
          <Button mb="m" href="mailto:parker@awarepath.com?subject=Hi Parker" target="_blank">
            <Icon position="absolute" left="m" type="email" />
            Contact us
          </Button>
          <Button mb="m" href="https://www.buymeacoffee.com/xJUZB6b" target="_blank">
            <Icon position="absolute" left="m" type="donate" />
            Donate
          </Button>
          {name && (
            <Button
              mb="m"
              onClick={() => {
                logout();
                toggleSideMenu();
              }}
            >
              <Icon position="absolute" left="m" type="logout" />
              Sign out
            </Button>
          )}
          <Box alignItems="center" justifyContent="center" pb="m">
            <Button
              backgroundColor="transparent"
              href="https://itunes.apple.com/us/app/streaking-daily-habit-tracker/id1462378374?mt=8"
              noShadow
              target="_blank"
            >
              <img
                alt="Aware iOS app"
                id="ios"
                src={appStore}
                style={{
                  width: 'auto',
                  minHeight: '50px',
                  maxHeight: '50px',
                  height: '50px',
                  flexShrink: 0
                }}
                title="Aware iOS app"
              />
            </Button>
          </Box>
          <Button fontSize="l" noShadow onClick={toggleSideMenu} to="/home">
            Aware
          </Button>
          <Button color="darkGrey" href="https://www.twosnotes.com" noShadow target="_blank">
            Created by Twos Technologies Incorporated
          </Button>
        </Body>
      </View>
    </div>
  );

SideMenu.propTypes = {
  activities: PropTypes.array.isRequired,
  events: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  sideMenu: PropTypes.bool.isRequired,
  toggleSideMenu: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
};

const mapStateToProps = ({ activities, events, sideMenu, user }) => ({
  activities,
  events,
  sideMenu,
  user
});

export default withRouter(
  connect(mapStateToProps, {
    ...userActions,
    ...sideMenuActions
  })(SideMenu)
);

// src/views/HomeView.js

import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import Body from '../components/Body';
import Box from '../components/Box';
import Button from '../components/Button';
import Img from '../components/Img';
import Text from '../components/Text';
import Title from '../components/Title';
import View from '../components/View';
import { appStore } from '../constants';

// eslint-disable-next-line
const HomeView = ({ history }) => {
  const bottom = (
    <Fragment>
      <Button hide id="videos" onClick={() => history.push('/videos')} pointer mr="s">
        Videos
      </Button>
      <Button
        hide
        href="mailto:hi@awarepath.com?subject=Hi Aware"
        target="_blank"
        id="contactUs"
        pointer
        mx="s"
      >
        Contact us
      </Button>
      <Button
        color="darkGrey"
        hide
        href="https://www.twosnotes.com"
        id="twos"
        pointer
        ml="s"
        target="_blank"
      >
        Created by Twos Technologies Incorporated
      </Button>
    </Fragment>
  );

  return (
    <View className="home">
      <Body alignItems="center" className="content" justifyContent="center" m="auto" p="m">
        <Box flexDirection="column" justifyContent="center">
          <Box alignItems="center" justifyContent="center" mb="m" mt="m">
            <Img
              alt="Aware"
              src="https://twos.s3.amazonaws.com/aware-free-daily-habit-tracker.png"
              size="100px"
            />
            <Title
              className="title"
              style={{ lineHeight: '5rem' }}
              fontSize="5rem !important"
              left
              pl="s"
              width="auto"
            >
              Aware
            </Title>
          </Box>
          <Text className="text" color="darkGrey" fontSize="xxl" mb="m" textAlign="center">
            A daily activity tracker
          </Text>
          <Box alignItems="center" flexDirection="column" mb="m" p="m">
            <Button
              backgroundColor="green"
              flexGrow={1}
              fontSize="l"
              onClick={() => history.push('/')}
              p="l"
              width="full"
              mb="l"
            >
              Start
            </Button>
            <Button
              flexGrow={1}
              fontSize="l"
              onClick={() => history.push('/signup')}
              p="l"
              width="full"
              mb="m"
            >
              Sign up / sign in
            </Button>
            <Box alignItems="center" className="apps" justifyContent="center" p="m">
              <a
                href="https://itunes.apple.com/us/app/streaking-daily-habit-tracker/id1462378374?mt=8"
                itemProp="url"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  alt="Aware iOS app"
                  className="ios-app"
                  id="ios"
                  src={appStore}
                  style={{
                    width: 'auto',
                    minHeight: '50px',
                    maxHeight: '50px',
                    height: '50px',
                    flexShrink: 0
                  }}
                  title="Aware iOS app"
                />
              </a>
            </Box>
          </Box>
        </Box>
        <Box alignItems="center" height="100%" justifyContent="center">
          <Box alignItems="flexStart" borderRadius="16px" boxShadow="true" noOverflow width="auto">
            <img
              alt="Aware Daily Activity Tracker"
              style={{ height: 'auto', width: '250px' }}
              src="https://twos.s3.amazonaws.com/aware-free-daily-habit-tracker-twos-technologies.jpg"
            />
          </Box>
        </Box>
        <Box justifyContent="center" m="auto" pt="l" className="inner-footer">
          {bottom}
        </Box>
      </Body>
      <Box justifyContent="center" m="auto" pt="l" className="footer">
        {bottom}
      </Box>
    </View>
  );
};

HomeView.propTypes = {
  history: PropTypes.object.isRequired
};

export default HomeView;

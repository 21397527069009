export const calculateAverage = (nominator, denominator) =>
  Math.round((nominator || 0) / (denominator || 1) * 100) / 100;

export const calculatePercent = (num, total) => Math.floor((num || 0) / (total || 1) * 100);
export const calculatePercentString = (num, total) => {
  const percent = calculatePercent(num, total);
  return `${percent > 100 ? 100 : percent}%`;
};

export const calculateRemainingString = (num, total) => {
  const diff = Number(total) - Number(num || 0);
  return diff === 0 ? `${0}` : diff > 0 ? `-${diff}` : `+${Math.abs(diff)}`;
};

// src/components/StartStats.js

import PropTypes from 'prop-types';
import React from 'react';

import Box from './Box';
import Text from './Text';
import { getDaysSinceStart, getStartDate } from '../utils/dataUtils';
import { dateToTitle } from '../utils/dateUtils';

const StartStats = ({ activities, event }) => {
  const startDate = getStartDate(event, activities);
  const daysSinceStart = getDaysSinceStart(event, activities);

  return (
    <Box flexDirection="column">
      <Box alignItems="center" justifyContent="spaceBetween" pb="m">
        <Text flexShrink={0}>Days Since Start</Text>
        <Text textAlign="right">{daysSinceStart}</Text>
      </Box>
      <Box alignItems="center" justifyContent="spaceBetween" pb="m">
        <Text>Started</Text>
        <Text textAlign="right">{dateToTitle(startDate)}</Text>
      </Box>
    </Box>
  );
};

StartStats.propTypes = {
  activities: PropTypes.array.isRequired,
  event: PropTypes.object.isRequired
};

export default StartStats;

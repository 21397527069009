// src/components/Label.js

import React from 'react';

import Text from './Text';

const Label = ({ children, ...props }) => (
  <Text p="l n m" {...props}>
    {children}
  </Text>
);

export default Label;

// src/reducers/index.js

import { combineReducers } from 'redux';

import activities from './activitiesReducer';
import activity from './activityReducer';
import chart from './chartReducer';
import day from './dayReducer';
import editEvent from './editEventReducer';
import editUser from './editUserReducer';
import error from './errorReducer';
import event from './eventReducer';
import events from './eventsReducer';
import loading from './loadingReducer';
import loadingAll from './loadingAllReducer';
import newEvent from './newEventReducer';
import search from './searchReducer';
import sideMenu from './sideMenuReducer';
import stats from './statsReducer';
import time from './timeReducer';
import user from './userReducer';

const rootReducer = combineReducers({
  activities,
  activity,
  chart,
  day,
  editEvent,
  editUser,
  error,
  event,
  events,
  loading,
  loadingAll,
  newEvent,
  search,
  sideMenu,
  stats,
  time,
  user
});

export default rootReducer;

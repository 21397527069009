// src/components/HideButton.js

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import * as eventActions from '../actions/eventActions';
import Button from './Button';

const HideButton = ({ event, saveEvent }) => (
    <Button mt="m" onClick={() => saveEvent({ ...event, hidden: !event.hidden })}>
      {event.hidden ? 'Show Activity' : 'Hide Activity'}
    </Button>
  );

HideButton.propTypes = {
  saveEvent: PropTypes.func.isRequired,
  event: PropTypes.object.isRequired
};

HideButton.propTypes = {
  saveEvent: PropTypes.func.isRequired,
  event: PropTypes.object.isRequired
};

const mapStateToProps = ({ event }) => ({ event });

export default connect(mapStateToProps, {
  ...eventActions
})(HideButton);

// src/utils/dateUtils.js

import { days, months, monthToNumber, shortMonths } from '../constants';

export const getChartDay = date => {
  date = new Date(date);
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
};

export const getWeek = thisDate => {
  const date = new Date(thisDate);
  date.setHours(0, 0, 0, 0);
  date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
  const week1 = new Date(date.getFullYear(), 0, 4);
  return (
    1 +
    Math.round(((date.getTime() - week1.getTime()) / 86400000 - 3 + (week1.getDay() + 6) % 7) / 7)
  );
};

export const getChartWeek = date => {
  date = new Date(date);
  return `${getWeek(date)}-${date.getFullYear()}`;
};

export const getChartMonth = date => {
  date = new Date(date);
  return `${date.getMonth() + 1}-${date.getFullYear()}`;
};

export const getChartYear = date => {
  date = new Date(date);
  return `${date.getFullYear()}`;
};

export const dateToTitle = date => {
  const newDate = new Date(date);

  const month = months[newDate.getMonth()];

  const d = newDate.getDate();

  const year = newDate.getFullYear();

  return `${month} ${d}, ${year}`;
};

export const calendarDateToTitle = date => {
  const dateParts = date.split('-');

  const newDate = new Date(Number(dateParts[0]), Number(dateParts[1]) - 1, Number(dateParts[2]));

  return dateToTitle(newDate);
};

export const dateToCalendarDate = date => {
  const newDate = new Date(date);

  const month = newDate.getMonth() + 1;

  const d = newDate.getDate();

  const year = newDate.getFullYear();

  return `${year}-${month < 10 ? `0${month}` : month}-${d < 10 ? `0${d}` : d}`;
};

export const daysInMonth = (month, year) => new Date(year, month, 0).getDate();

export const daysInThisMonth = () =>
  new Date(new Date().getFullYear(), new Date().getMonth(), 0).getDate();

export const getTodaysDate = () => new Date();

export const getTodaysTitle = () => {
  const today = getTodaysDate();

  return dateToTitle(today);
};

export const getNextDate = date => date.setDate(date.getDate() + 1);

export const getPastDate = date => date.setDate(date.getDate() - 1);

export const getYesterdaysTitle = () => {
  const today = getTodaysDate();
  const yesterday = getPastDate(today);

  return dateToTitle(yesterday);
};

export const titleToDate = title => {
  const titleParts = title.replace(',', '').split(' ');

  const month = monthToNumber[titleParts[0]];

  const day = titleParts[1];

  const year = titleParts[2];

  return new Date(year, month, day);
};

export const titleToTitle = title => {
  const newDate = titleToDate(title);

  const day = days[newDate.getDay()];

  const month = shortMonths[newDate.getMonth()];

  const d = newDate.getDate();

  const year = newDate.getFullYear();

  return `${day} ${month} ${d}, ${year}`;
};

export const isToday = date => {
  const dateTitle = dateToTitle(new Date(date));
  const todaysTitle = getTodaysTitle();

  return dateTitle === todaysTitle;
};

export const isYesterday = date => {
  const dateTitle = dateToTitle(new Date(date));
  const yesterdaysTitle = getYesterdaysTitle();

  return dateTitle === yesterdaysTitle;
};

export const isStreaking = (thisDate, lastDate) => {
  const dayBeforeTitle = dateToTitle(getPastDate(new Date(thisDate)));
  const lastDateTitle = dateToTitle(new Date(lastDate));

  return dayBeforeTitle === lastDateTitle;
};

export const daysBetweenToday = date =>
  Math.floor((Date.parse(new Date()) - Date.parse(new Date(date))) / 86400000);

export const isThisWeek = date => {
  const thisDate = new Date(date);
  return getWeek(new Date()) === getWeek(thisDate) && isThisYear(thisDate);
};

export const isLastWeek = date => {
  const thisDate = new Date(date);
  let lastWeek = new Date();
  // if (lastWeek.getDate() - 7 <= 0) {
  //   if (lastWeek.getMonth() - 1 <= 0) {
  //     lastWeek.setFullYear(lastWeek.getFullYear() - 1);
  //   }
  //   lastWeek.setMonth(lastWeek.getMonth() - 1);
  // }
  lastWeek.setDate(lastWeek.getDate() - 7);
  return getWeek(lastWeek) === getWeek(thisDate) && isSameYear(lastWeek, thisDate);
};

export const isThisMonth = date => {
  const thisDate = new Date(date);
  const today = new Date();

  return thisDate.getMonth() === today.getMonth() && isThisYear(date);
};

export const isLastMonth = date => {
  const thisDate = new Date(date);
  let lastMonth = new Date();
  // if (lastMonth.getMonth() - 1 <= 0) {
  //   lastMonth.setFullYear(lastMonth.getFullYear() - 1);
  // }
  lastMonth.setMonth(lastMonth.getMonth() - 1);
  return lastMonth.getMonth() === thisDate.getMonth() && isSameYear(lastMonth, thisDate);
};

export const isSameYear = (date1, date2) => {
  const firstDate = new Date(date1);
  const secondDate = new Date(date2);

  return firstDate.getFullYear() === secondDate.getFullYear();
};

export const isThisYear = date => {
  const thisDate = new Date(date);
  const today = new Date();

  return thisDate.getFullYear() === today.getFullYear();
};

export const isLastYear = date => {
  const thisDate = new Date(date);
  let lastYear = new Date();
  lastYear.setFullYear(lastYear.getFullYear() - 1);
  return isSameYear(lastYear, thisDate);
};

export const isYear = (date, year) => {
  const thisDate = new Date(date);

  return thisDate.getFullYear() === year;
};

export const getUpdatedDate = (updated, moreMilliseconds = 0) => {
  const date = new Date(updated);

  const milliseconds = date.getMilliseconds() + 1 + moreMilliseconds;

  return new Date(date.setMilliseconds(milliseconds));
};

export const getFirstDayOfWeek = () => {
  const today = new Date();
  const day = today.getDay();
  const diff = today.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
  return new Date(today.setDate(diff));
};

export const getFirstDayOfMonth = () => {
  const today = new Date();
  return new Date(today.getFullYear(), today.getMonth(), 1);
}

export const getFirstDayOfYear = () => {
  const today = new Date();
  return new Date(today.getFullYear(), 0, 1);
}

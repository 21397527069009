// src/components/SubmitButton.js

import React from 'react';
import styled from 'styled-components';

import { borderRadii, colors, fontSizes, fontWeights } from '../constants';
import { buttonShadow, margin, padding } from '../utils/styleUtils';

const SubmitButton = styled(
  ({
    alignItems,
    backgroundColor,
    borderRadius,
    boxShadow: bs,
    button,
    children,
    dispatch,
    flex,
    flexDirection,
    flexGrow,
    flexShrink,
    flexWrap,
    hide,
    hover,
    href,
    justifyContent,
    m,
    mb,
    ml,
    mr,
    mt,
    mx,
    my,
    maxHeight,
    minHeight,
    noHover,
    noMin,
    noShadow,
    p,
    pb,
    pl,
    pr,
    pt,
    px,
    py,
    pointer,
    s3Url,
    staticContext,
    textAlign,
    to,
    type,
    uploadedFiles,
    uploaderOptions,
    width,
    ...rest
  }) => (
    <button type={type || 'button'} {...rest}>
      {children}
    </button>
  )
)`
  background-color: ${({ backgroundColor, hide }) =>
    colors[backgroundColor || (hide ? 'transparent' : 'white')]};
  border-radius: ${borderRadii.button};
  ${buttonShadow};
  color: ${({ color: c }) => colors[c || 'black']};
  cursor: pointer;
  font-size: ${fontSizes.m};
  font-weight: ${fontWeights.m};
  ${props => margin(props)};
  ${props => padding(props)};
  width: 100%;
`;

SubmitButton.defaultProps = {
  alignItems: 'center',
  borderRadius: 'button',
  flex: '0 0 auto',
  fontSize: 'm',
  fontWeight: 'm',
  justifyContent: 'center',
  p: 'm'
};

export default SubmitButton;

// src/actions/actionTypes.js

export default {
  CHANGE_ACTIVITY: 'CHANGE_ACTIVITY',
  CREATE_ACTIVITY: 'CREATE_ACTIVITY',
  CREATE_ACTIVITY_SUCCESS: 'CREATE_ACTIVITY_SUCCESS',
  EDIT_ACTIVITY: 'EDIT_ACTIVITY',
  LOAD_ACTIVITIES_REQUEST: 'LOAD_ACTIVITIES_REQUEST',
  LOAD_ACTIVITIES_SUCCESS: 'LOAD_ACTIVITIES_SUCCESS',
  LOAD_TODAY_ACTIVITIES_REQUEST: 'LOAD_TODAY_ACTIVITIES_REQUEST',
  LOAD_TODAY_ACTIVITIES_SUCCESS: 'LOAD_TODAY_ACTIVITIES_SUCCESS',
  SAVE_ACTIVITY: 'SAVE_ACTIVITY',

  LOAD_DAY: 'LOAD_DAY',

  CHANGE_EVENT: 'CHANGE_EVENT',
  CHANGE_EVENT_GOAL: 'CHANGE_EVENT_GOAL',
  CHANGE_EVENT_NAME: 'CHANGE_EVENT_NAME',
  CHANGE_EVENT_TYPE: 'CHANGE_EVENT_TYPE',
  CHANGE_EVENT_UNITS: 'CHANGE_EVENT_UNITS',
  CHANGE_EVENT_COLOR: 'CHANGE_EVENT_COLOR',
  CHANGE_EVENT_ZEROS: 'CHANGE_EVENT_ZEROS',
  CHANGE_EVENT_INCREMENT_VALUE: 'CHANGE_EVENT_INCREMENT_VALUE',
  CREATE_EVENT: 'CREATE_EVENT',
  CREATED_EVENT: 'CREATED_EVENT',
  DELETE_EVENT: 'DELETE_EVENT',
  EDIT_EVENT: 'EDIT_EVENT',
  LOAD_EVENT: 'LOAD_EVENT',
  LOAD_EVENTS: 'LOAD_EVENTS',
  SAVE_EVENT: 'SAVE_EVENT',
  UPDATE_EVENT: 'UPDATE_EVENT',

  DARK_MODE_ON: 'DARK_MODE_ON',
  DARK_MODE_OFF: 'DARK_MODE_OFF',
  TOGGLE_DARK_MODE: 'TOGGLE_DARK_MODE',

  CREATE_USER_REQUEST: 'CREATE_USER_REQUEST',

  CLOSE_SIDE_MENU: 'CLOSE_SIDE_MENU',
  TOGGLE_SIDE_MENU: 'TOGGLE_SIDE_MENU',

  CHANGE_CODE: 'CHANGE_CODE',
  CHANGE_EMAIL: 'CHANGE_EMAIL',
  CHANGE_NAME: 'CHANGE_NAME',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',

  LOGIN_USER: 'LOGIN_USER',
  SIGNUP_USER: 'SIGNUP_USER',

  LOGOUT: 'LOGOUT',

  REQUEST: 'REQUEST',

  SEARCH: 'SEARCH',

  CLEAR_ERROR: 'CLEAR_ERROR',
  ERROR: 'ERROR',

  CLEAR_STATS: 'CLEAR_STATS',
  LOAD_ACTIVITY_STATS_SUCCESS: 'LOAD_ACTIVITY_STATS_SUCCESS',
  LOAD_EVENT_STATS_SUCCESS: 'LOAD_EVENT_STATS_SUCCESS',
  LOAD_USER_STATS_SUCCESS: 'LOAD_USER_STATS_SUCCESS',

  NEXT_TIME: 'NEXT_TIME',
  PAST_TIME: 'PAST_TIME',
  SET_TIME: 'SET_TIME',

  NEXT_CHART: 'NEXT_CHART',
  PAST_CHART: 'PAST_CHART',
  SET_CHART: 'SET_CHART',

  EMAIL_CODE_SUCCESS: 'EMAIL_CODE_SUCCESS',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS'
};

// src/views/EventView.js

import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import * as dayActions from '../actions/dayActions';
import * as eventActions from '../actions/eventActions';
import * as searchActions from '../actions/searchActions';
import BackButton from '../components/BackButton';
import Body from '../components/Body';
import DayBarChart from '../components/DayBarChart';
import EventStats from '../components/EventStats';
import ExportButton from '../components/ExportButton';
import Header from '../components/Header';
import HeaderButton from '../components/HeaderButton';
import BarChart from '../components/BarChart';
import HideButton from '../components/HideButton';
import Icon from '../components/Icon';
import Input from '../components/Input';
import LineChart from '../components/LineChart';
import Loading from '../components/Loading';
import PieChart from '../components/PieChart';
import Grid from '../components/Grid';
import TimeSelect from '../components/TimeSelect';
import Title from '../components/Title';
import View from '../components/View';
import { types } from '../constants';
import { calendarDateToTitle } from '../utils/dateUtils';

class EventView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasMounted: false,
      showEvents: false
    };

    this.handleLoadDay = this.handleLoadDay.bind(this);
    this.handleLoadEvent = this.handleLoadEvent.bind(this);
  }

  componentDidMount() {
    const { changeSearch, history, event } = this.props;
    if (!event.name) {
      history.push('/');
    }

    this.setState({ hasMounted: true });

    changeSearch('');

    setTimeout(() => this.setState({ showEvents: true }), 0);
  }

  componentWillReceiveProps(nextProps) {
    const { time } = this.props;
    const { time: nextTime } = nextProps;

    if (time !== nextTime) {
      this.setState({ showEvents: false }, () =>
        setTimeout(() => this.setState({ showEvents: true }), 0)
      );
    }
  }

  handleLoadDay(data) {
    const { history, loadDay } = this.props;

    loadDay(calendarDateToTitle(data.date));

    history.push('/');
  }

  handleLoadEvent(event) {
    const { editEvent, history } = this.props;

    editEvent(event);

    history.push('/editEvent');
  }

  render() {
    const { changeSearch, event, search } = this.props;
    const { hasMounted, showEvents } = this.state;

    const isText = event.type === types.TEXT;

    return hasMounted ? (
      <View>
        <Helmet>
          <title>{event.name}</title>
          <meta name="description" content={`Track daily activities like ${event.name}`} />
        </Helmet>
        <Header noBorder zIndex={20} pb="s">
          <BackButton />
          <Title>{event.name}</Title>
          <HeaderButton onClick={() => this.handleLoadEvent(event)}>
            <Icon type="edit" />
          </HeaderButton>
        </Header>
        <Header py="s">
          <TimeSelect event={event} />
        </Header>
        {isText && (
          <Header pt="s">
            <Input
              onChange={e => changeSearch(e.target.value)}
              placeholder={`Search ${event.name.toLowerCase()}...`}
              textAlign="center"
              value={search}
              width="full"
            />
          </Header>
        )}
        <Body p="m">
          {showEvents ? (
            <Fragment>
              <Grid event={event} />
              <LineChart event={event} />
              <BarChart event={event} />
              <PieChart event={event} />
              <DayBarChart event={event} />
              <EventStats event={event} />
              <HideButton event={event} />
              <ExportButton event={event} />
            </Fragment>
          ) : (
            <Loading />
          )}
        </Body>
      </View>
    ) : null;
  }
}

EventView.propTypes = {
  changeSearch: PropTypes.func.isRequired,
  editEvent: PropTypes.func.isRequired,
  event: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  loadDay: PropTypes.func.isRequired,
  search: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired
};

const mapStateToProps = ({ event, search, time }) => ({
  event,
  search,
  time
});

export default connect(mapStateToProps, {
  ...dayActions,
  ...eventActions,
  ...searchActions
})(EventView);

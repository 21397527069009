import initialState from './initialState';
import ActionTypes from '../actions/actionTypes';

const statsReducer = (state = initialState.stats, action) => {
  switch (action.type) {
    case ActionTypes.LOAD_USER_STATS_SUCCESS:
      return {
        ...state,
        users: action.users,
      };

    case ActionTypes.LOAD_EVENT_STATS_SUCCESS:
      return {
        ...state,
        events: action.events,
      };

    case ActionTypes.LOAD_ACTIVITY_STATS_SUCCESS:
      return {
        ...state,
        activities: action.activities,
      };

    case ActionTypes.CLEAR_STATS:
      return initialState.stats;

    default:
      return state;
  }
};

export default statsReducer;

// src/components/TimeSelect.js

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import * as timeActions from '../actions/timeActions';
import Box from './Box';
import Button from './Button';
import { times } from '../constants';
import { colorToLightColor } from '../utils/stringUtils';

const TimeSelect = ({ event, time, setTime, ...props }) => {
  const [hasMounted, setHasMounted] = useState(false);

  // eslint-disable-next-line
  useEffect(() => (hasMounted ? false : setHasMounted(true)), []);

  return (
    hasMounted && (
      <Box alignItems="center" borderRadius="button" boxShadow noOverflow {...props}>
        {Object.values(times).map(t => (
          <Button
            backgroundColor={
              event && event.color && time === t ? colorToLightColor(event.color) : 'transparent'
            }
            borderRadius="none"
            flexGrow="1"
            hover={time !== t}
            key={t}
            onClick={() => setTime(t)}
          >
            {t}
          </Button>
        ))}
      </Box>
    )
  );
};

TimeSelect.propTypes = {
  event: PropTypes.object,
  setTime: PropTypes.func.isRequired,
  time: PropTypes.string.isRequired
};

TimeSelect.defaultProps = {
  event: null
};

const mapStateToProps = ({ time }) => ({ time });

export default connect(mapStateToProps, { ...timeActions })(TimeSelect);

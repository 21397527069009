import initialState from './initialState';
import ActionTypes from '../actions/actionTypes';

const editEventReducer = (state = initialState.editEvent, action) => {
  switch (action.type) {
    case ActionTypes.CHANGE_EVENT_GOAL:
      return {
        ...state,
        goal: action.goal
      };

    case ActionTypes.CHANGE_EVENT_NAME:
      return {
        ...state,
        name: action.name
      };

    case ActionTypes.CHANGE_EVENT_TYPE:
      return {
        ...state,
        type: action.newType
      };

    case ActionTypes.CHANGE_EVENT_UNITS:
      return {
        ...state,
        units: action.units
      };

    case ActionTypes.CHANGE_EVENT_COLOR:
      return {
        ...state,
        color: action.color
      };

    case ActionTypes.CHANGE_EVENT_ZEROS:
      return {
        ...state,
        zeros: action.zeros
      };

    case ActionTypes.EDIT_EVENT:
    case ActionTypes.SAVE_EVENT:
      return action.event;

    case ActionTypes.CREATE_EVENT:
      return initialState.editEvent;

    default:
      return state;
  }
};

export default editEventReducer;

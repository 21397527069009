// import ES6Promise from 'es6-promise';
// ES6Promise.polyfill();
import axios from 'axios';
import ActionTypes from './actionTypes';
import { getTodaysTitle } from '../utils/dateUtils';
// import { checkOffline } from './offlineActions';

export const changeActivity = value => dispatch =>
  dispatch({ type: ActionTypes.CHANGE_ACTIVITY, value });

export const createActivity = activity => dispatch => {
  dispatch({ type: ActionTypes.CREATE_ACTIVITY, activity });

  axios.post('/api/activity', { activity }).then(response =>
    dispatch({
      type: ActionTypes.CREATE_ACTIVITY_SUCCESS,
      activity: response.data.activity
    })
  );
};

export const editActivity = activity => dispatch =>
  dispatch({ type: ActionTypes.EDIT_ACTIVITY, activity });

export const loadActivities = user_id => dispatch => {
  dispatch({ type: ActionTypes.LOAD_ACTIVITIES_REQUEST });
  axios.get(`/api/activity/${user_id}`).then(response =>
    dispatch({
      type: ActionTypes.LOAD_ACTIVITIES_SUCCESS,
      activities: response.data.activities
    })
  );
};

export const loadTodayActivities = user_id => dispatch => {
  dispatch({ type: ActionTypes.LOAD_TODAY_ACTIVITIES_REQUEST });

  axios.post(`/api/activity/${user_id}/today`, { date: getTodaysTitle() }).then(response =>
    dispatch({
      type: ActionTypes.LOAD_TODAY_ACTIVITIES_SUCCESS,
      activities: response.data.activities
    })
  );
};

export const saveActivity = activity => dispatch => {
  activity.updated = new Date();

  dispatch({ type: ActionTypes.SAVE_ACTIVITY, activity });

  return axios.patch('/api/activity', { activity });
};

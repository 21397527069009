// src/components/BarChart.js

import PropTypes from 'prop-types';
import React from 'react';
import { ColumnChart } from 'react-chartkick';
import { connect } from 'react-redux';

import Box from './Box';
import { colors, types } from '../constants';
import {
  getEventActivitiesForTime,
  getEventActivitiesGoals,
  getEventActivitiesValuesSearched
} from '../utils/dataUtils';
import { colorToDarkColor } from '../utils/stringUtils';

const BarChart = ({ activities, event, search, time }) => {
  const eventActivities = getEventActivitiesForTime(event, activities, time);

  if (!eventActivities.length) return null;

  const values = getEventActivitiesValuesSearched(event, eventActivities, search, time);
  const goals = getEventActivitiesGoals(event, eventActivities);

  let regularData = [];
  let goalData = [];
  let high = 0;
  let goalHigh = 0;

  Object.keys(values).forEach(key => {
    regularData = [...regularData, [key, values[key]]];
    if (values[key] > high) high = values[key];
  });

  Object.keys(goals).forEach(key => {
    goalData = [...goalData, [key, goals[key]]];
    if (goals[key] > goalHigh) goalHigh = goals[key];
  });

  const style = {
    borderWidth: 0,
    hoverBorderWidth: 0
  };

  return (
    <Box className="mobile-column" pb="m">
      <Box pb="m">
        <ColumnChart
          data={regularData}
          colors={[colors[event.color ? colorToDarkColor(event.color) : 'green']]}
          library={style}
          dataset={style}
          max={high + 1}
        />
      </Box>
      {event.type === types.COUNT &&
        !!event.goal && (
          <ColumnChart
            data={goalData}
            colors={[colors[event.color ? colorToDarkColor(event.color) : 'green']]}
            library={style}
            dataset={style}
            max={goalHigh + 1}
          />
        )}
    </Box>
  );
};

BarChart.propTypes = {
  activities: PropTypes.array.isRequired,
  event: PropTypes.object.isRequired,
  search: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired
};

const mapStateToProps = ({ activities, search, time }) => ({ activities, search, time });

export default connect(mapStateToProps)(BarChart);

// src/components/EventStats.js

import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import { types } from '../constants';
import CountStats from './CountStats';
import DayStats from './DayStats';
import RecordStats from './RecordStats';
import StartStats from './StartStats';
import StreakStats from './StreakStats';
import Text from './Text';
import TimeStats from './TimeStats';
import { getEventActivities, getEventActivitiesSearched } from '../utils/dataUtils';
import { sortByDate } from '../utils/sortUtils';

const EventStats = ({ activities, event, search }) => {
  if (!activities.length) {
    return (
      <Text py="m" textAlign="center">
        No activities logged yet :)
      </Text>
    );
  }

  let eventActivities = getEventActivities(event, activities);

  const numberOfActivities = eventActivities.length;

  if (!numberOfActivities) {
    return (
      <Text py="m" textAlign="center">
        No activities logged yet :)
      </Text>
    );
  }

  eventActivities = eventActivities.sort(sortByDate);
  const allEventActivities = eventActivities;

  if (event.type === types.TEXT) {
    eventActivities = getEventActivitiesSearched(eventActivities, search);
  }

  return (
    <Fragment>
      <StreakStats activities={eventActivities} event={event} />
      <TimeStats activities={eventActivities} allActivities={allEventActivities} event={event} search={search} />
      <RecordStats activities={eventActivities} allActivities={allEventActivities} event={event}  />
      <CountStats activities={eventActivities} allActivities={allEventActivities} event={event} />
      <DayStats activities={eventActivities} allActivities={allEventActivities} event={event} search={search} />
      <StartStats activities={eventActivities} allActivities={allEventActivities} event={event} />
    </Fragment>
  );
};

EventStats.propTypes = {
  activities: PropTypes.array.isRequired,
  event: PropTypes.object.isRequired,
  search: PropTypes.string.isRequired
};

const mapStateToProps = ({ activities, search }) => ({ activities, search });

export default connect(mapStateToProps)(EventStats);

// import ES6Promise from 'es6-promise';
// ES6Promise.polyfill();
import axios from 'axios';
import ActionTypes from './actionTypes';
// import { checkOffline } from './offlineActions';

export const changeEvent = event => ({ type: ActionTypes.CHANGE_EVENT, event });
export const changeEventGoal = goal => ({ type: ActionTypes.CHANGE_EVENT_GOAL, goal });
export const changeEventName = name => ({ type: ActionTypes.CHANGE_EVENT_NAME, name });
export const changeEventType = newType => ({ type: ActionTypes.CHANGE_EVENT_TYPE, newType });
export const changeEventUnits = units => ({ type: ActionTypes.CHANGE_EVENT_UNITS, units });
export const changeEventColor = color => ({ type: ActionTypes.CHANGE_EVENT_COLOR, color });
export const changeEventIncrementValue = (event, incrementValue) => ({ type: ActionTypes.CHANGE_EVENT_INCREMENT_VALUE, event, incrementValue });
export const changeEventZeros = zeros => ({ type: ActionTypes.CHANGE_EVENT_ZEROS, zeros });

export const createEvent = event => dispatch => {
  dispatch({ type: ActionTypes.CREATE_EVENT, event });

  return axios.post('/api/event', { event }).then(response =>
    dispatch({
      type: ActionTypes.CREATED_EVENT,
      event: response.data.event
    })
  );
};

export const deleteEvent = event => dispatch => {
  dispatch({ type: ActionTypes.DELETE_EVENT, event });

  return axios.post('/api/event/delete', { event });
};

export const editEvent = event => ({ type: ActionTypes.EDIT_EVENT, event });

export const loadEvent = event => ({ type: ActionTypes.LOAD_EVENT, event });

export const loadEvents = user_id => dispatch =>
  axios.get(`/api/event/${user_id}`).then(response =>
    dispatch({
      type: ActionTypes.LOAD_EVENTS,
      events: response.data.events
    })
  );

export const saveEvent = event => dispatch => {
  dispatch({ type: ActionTypes.SAVE_EVENT, event });

  return axios.patch('/api/event', { event });
};

export const updateEvent = event => dispatch => {
  dispatch({ type: ActionTypes.UPDATE_EVENT, event });

  return axios.post('/api/event/update', { event });
};

import { sortByDate, sortByUpdated } from './sortUtils';

const convertToCSV = objArray => {
  const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
  let str = '';

  for (let i = 0; i < array.length; i += 1) {
    let line = '';

    // eslint-disable-next-line
    for (const index in array[i]) {
      if (line !== '') line += ',';

      line += array[i][index];
    }

    str += `${line}\n`;
  }

  return str;
};

const exportCSVFile = (headers, items, fileTitle) => {
  if (headers) {
    items.unshift(headers);
  }

  // Convert Object to JSON
  const jsonObject = JSON.stringify(items);

  const csv = convertToCSV(jsonObject);

  const exportedFilenmae = `${fileTitle}.csv` || 'export.csv';

  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, exportedFilenmae);
  } else {
    const link = document.createElement('a');
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', exportedFilenmae);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};

export const download = (events, activities) => {
  const headers = {
    date: 'Date'
  };

  const eventValues = {};

  events
    .sort(sortByUpdated)
    .filter(event => !event.hidden)
    .forEach(event => {
      headers[event._id] = event.name;
      eventValues[event._id] = ' ';
    });

  events
    .sort(sortByUpdated)
    .filter(event => !!event.hidden)
    .forEach(event => {
      headers[event._id] = event.name;
      eventValues[event._id] = ' ';
    });

  const formattedActivities = [];

  // format the data
  activities.sort(sortByDate).forEach(activity => {
    if (eventValues[activity.event_id]) {
      const activityDate = activity.date.replace(',', '');
      const activityValue = activity.value ? activity.value.replace(/,/g, '') : ' ';
      const index = formattedActivities.findIndex(a => a.date === activityDate);
      if (index !== -1) {
        formattedActivities[index] = {
          ...formattedActivities[index],
          [activity.event_id]: activityValue
        };
      } else {
        formattedActivities.push({
          date: activityDate,
          ...eventValues,
          [activity.event_id]: activityValue
        });
      }
    }
  });

  const fileTitle = 'Aware';

  exportCSVFile(headers, formattedActivities, fileTitle);
};

import initialState from './initialState';
import ActionTypes from '../actions/actionTypes';

const activityReducer = (state = initialState.activity, action) => {
  switch (action.type) {
    case ActionTypes.CHANGE_ACTIVITY:
      return {
        ...state,
        updated: new Date(),
        value: action.value,
      };

    case ActionTypes.CREATE_ACTIVITY:
    case ActionTypes.EDIT_ACTIVITY:
      return action.activity;

    case ActionTypes.LOAD_TODAY_ACTIVITIES_SUCCESS: {
      const { activities } = action;

      activities.forEach(activity => {
        // did we load the same activity?
        if (activity._id === state._id || (activity.date === state.date && activity.event_id === state.event_id)) {
          // update current activity if the loaded activity was updated more recently
          const currentActivityUpdated = new Date(state.updated);
          const activityUpdated = new Date(activity.updated);
          if (currentActivityUpdated < activityUpdated) {
            return activity;
          }
        }
      });

      return state;
    }

    case ActionTypes.LOGOUT:
    case ActionTypes.SAVE_ACTIVITY:
      return initialState.activity;

    default:
      return state;
  }
};

export default activityReducer;

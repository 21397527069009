// src/components/Form.js

import React from 'react';
import styled from 'styled-components';

import { align, widths } from '../constants';
import { margin, padding } from '../utils/styleUtils';

const Form = styled(({ // eslint-disable-next-line
  backgroundColor, children, flex, flexDirection, flexGrow, flexWrap, margin, width, ...rest }) => (
  <form {...rest}>{children}</form>
))`
  ${({ alignItems: ai }) => ai && `align-items: ${align[ai]}`};
  ${({ alignSelf: as }) => as && `align-self: ${align[as]}`};
  display: flex;
  ${({ flex: f }) => f && `flex: ${f}`};
  ${({ flexDirection: fd }) => fd && `flex-direction: ${align[fd]}`};
  ${({ flexGrow: fg }) => fg && `flex-grow: ${fg}`};
  ${({ flexShrink: fs }) => fs && `flex-shrink: ${fs}`};
  ${({ flexWrap: fw }) => fw && `flex-wrap: ${fw}`};
  ${({ justifyContent: jc }) => jc && `justify-content: ${align[jc]}`};
  ${props => margin(props)};
  max-width: ${({ width }) => widths[width]};
  ${props => padding(props)};
  width: 100%;
  /* input[type='text'],
  input[type='password'] {
    margin: 0 auto;
  } */
`;

Form.defaultProps = {
  flex: '0 0 auto',
  flexDirection: 'column',
  m: '0 auto',
  width: 'full'
};

export default Form;

import PropTypes from 'prop-types';
import React from 'react';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import { connect } from 'react-redux';

import * as dayActions from '../actions/dayActions';
import Box from '../components/Box';
import Modal from '../components/Modal';
import { dateToTitle } from '../utils/dateUtils';

const CalendarModal = ({ loadDay, onClose }) => (
  <Modal onClick={onClose}>
    <Box justifyContent="center" p="m">
      <DayPicker
        onDayClick={day => {
          const title = dateToTitle(day);

          loadDay(title);

          onClose();
        }}
      />
    </Box>
  </Modal>
);

CalendarModal.propTypes = {
  loadDay: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default connect(null, { ...dayActions })(CalendarModal);

// src/utils/elementUtils.js

export const getElementById = id => document.getElementById(id);

export const focusElement = element => {
  if (element) {
    element.focus();

    const { value } = element;
    element.value = '';
    element.value = value;
  }
};

export const focusElementById = id => focusElement(getElementById(id));

export const blurElement = element => {
  if (element) element.blur();
};

export const blurElementById = id => blurElement(getElementById(id));

export const scrollToElement = element => {
  if (element) element.scrollIntoView();
};

export const scrollToElementById = id => scrollToElement(getElementById(id));

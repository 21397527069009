import initialState from './initialState';
import ActionTypes from '../actions/actionTypes';

const eventReducer = (state = initialState.event, action) => {
  switch (action.type) {
    case ActionTypes.LOAD_EVENT:
    case ActionTypes.SAVE_EVENT:
      return action.event;

    case ActionTypes.LOGOUT:
      return initialState.event;

    default:
      return state;
  }
};

export default eventReducer;

// src/components/DayBarChart.js

import PropTypes from 'prop-types';
import React from 'react';
import { ColumnChart } from 'react-chartkick';
import { connect } from 'react-redux';

import Box from './Box';
import { colors, types, weekDays } from '../constants';
import { getEventActivitiesForTime, getEventActivitiesSearched } from '../utils/dataUtils';
import { titleToDate } from '../utils/dateUtils';
import { didMeetGoal } from '../utils/statUtils';
import { colorToDarkColor } from '../utils/stringUtils';

const DayBarChart = ({ activities, event, search, time }) => {
  let eventActivities = getEventActivitiesForTime(event, activities, time);

  const { type } = event;

  const isText = type === types.TEXT;
  if (isText) {
    eventActivities = getEventActivitiesSearched(eventActivities, search);
  }

  const numberOfActivities = eventActivities.length;

  if (!numberOfActivities) return null;

  const isBoolean = type === types.BOOLEAN;

  const days = new Array(7).fill(0);
  const daysCount = new Array(7).fill(0);

  for (let i = 0; i < numberOfActivities; i += 1) {
    const currentActivity = eventActivities[i];
    const currentActivityDate = titleToDate(currentActivity.date);

    let currentActivityValue;
    if (isText) {
      if (search) {
        const searchRegularExpression = new RegExp(search, 'g');
        currentActivityValue = (currentActivity.value.match(searchRegularExpression) || []).length;
      } else {
        currentActivityValue = currentActivity.value ? 1 : 0;
      }
    } else {
      currentActivityValue = Number(currentActivity.value);
    }

    const hitGoal = didMeetGoal(currentActivityValue, event);

    if (isBoolean) currentActivityValue = Number(hitGoal);

    const dayOfWeek = currentActivityDate.getDay();
    days[dayOfWeek] += 1;
    daysCount[dayOfWeek] += currentActivityValue;
  }

  let regularData = [];
  let max = 0;

  Object.keys(daysCount).forEach(key => {
    const value = daysCount[key];
    if (value > max) max = value;
    regularData = [...regularData, [weekDays[key], value]];
  });

  const style = { borderWidth: 0, hoverBorderWidth: 0 };

  const chart = data => (
    <ColumnChart
      data={data}
      className="chart"
      colors={[colors[event.color ? colorToDarkColor(event.color) : 'green']]}
      library={style}
      dataset={style}
      max={max}
    />
  );

  if (regularData.length > 1) {
    regularData = [...regularData.slice(1), regularData[0]];
  }

  return <Box pb="m">{chart(regularData)}</Box>;
};

DayBarChart.propTypes = {
  activities: PropTypes.array.isRequired,
  event: PropTypes.object.isRequired,
  search: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired
};

const mapStateToProps = ({ activities, search, time }) => ({ activities, search, time });

export default connect(mapStateToProps)(DayBarChart);

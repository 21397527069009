// src/components/StreakStats.js

import PropTypes from 'prop-types';
import React from 'react';

import Box from './Box';
import { types } from '../constants';
import Text from './Text';
import { getCurrentStreak, getLongestStreak } from '../utils/statUtils';

const StreakStats = ({ activities, event, search }) => {
  const { type } = event;

  if (type === types.SCALE) return null;

  return (
    <Box flexDirection="column" pb="m">
      <Box justifyContent="center" pb="m">
        <Text>Streaks</Text>
      </Box>
      <Box alignItems="center" justifyContent="spaceBetween" pb="m">
        <Text>Current</Text>
        <Text textAlign="right">{getCurrentStreak(activities, event)}</Text>
      </Box>
      <Box alignItems="center" justifyContent="spaceBetween" pb="m">
        <Text>Longest</Text>
        <Text textAlign="right">{getLongestStreak(activities, event)}</Text>
      </Box>
    </Box>
  );
};

StreakStats.propTypes = {
  activities: PropTypes.array.isRequired,
  event: PropTypes.object.isRequired
};

export default StreakStats;

// src/components/PieChart.js

import PropTypes from 'prop-types';
import React from 'react';
import { PieChart } from 'react-chartkick';
import { connect } from 'react-redux';

import Box from './Box';
import { types } from '../constants';
import {
  getEventActivitiesForTime,
  getEventActivitiesValuesSearched,
  getEventActivitiesGoals,
  getPieColor
} from '../utils/dataUtils';
import { calculatePercent } from '../utils/numberUtils';

const PieChartComponent = ({ activities, event, search, time }) => {
  let eventActivities = getEventActivitiesForTime(event, activities, time);

  if (!eventActivities.length) return null;

  const values = getEventActivitiesValuesSearched(event, eventActivities, search, time);
  const goals = getEventActivitiesGoals(event, eventActivities);

  let regularData = [];
  let goalData = [];
  let high = 0;

  Object.keys(values).forEach(key => {
    const percent = calculatePercent(values[key], eventActivities.length);
    regularData = [...regularData, [key, percent]];
    if (percent > high) high = percent;
  });

  Object.keys(goals).forEach(key => {
    goalData = [...goalData, [key, calculatePercent(goals[key], eventActivities.length)]];
  });

  const style = {
    borderWidth: 0,
    hoverBorderWidth: 0
  };

  const chart = data => (
    <PieChart
      data={data}
      className="chart"
      colors={getPieColor(event, data, high)}
      legend={regularData.length > 10 ? false : 'bottom'}
      suffix="%"
      library={style}
      dataset={style}
    />
  );

  return (
    <Box className="mobile-column" pb="m">
      <Box>{chart(regularData)}</Box>
      {event.type === types.COUNT && !!event.goal && chart(goalData)}
    </Box>
  );
};

PieChartComponent.propTypes = {
  activities: PropTypes.array.isRequired,
  event: PropTypes.object.isRequired,
  search: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired
};

const mapStateToProps = ({ activities, search, time }) => ({ activities, search, time });

export default connect(mapStateToProps)(PieChartComponent);

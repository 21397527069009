// src/views/AllView.js

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import * as activityActions from '../actions/activityActions';
import * as dayActions from '../actions/dayActions';
import * as eventActions from '../actions/eventActions';
import BackButton from '../components/BackButton';
import Body from '../components/Body';
import Box from '../components/Box';
import Button from '../components/Button';
import Chart from '../components/Chart';
import ChartSelect from '../components/ChartSelect';
import Header from '../components/Header';
import HeaderButton from '../components/HeaderButton';
import Icon from '../components/Icon';
import Loading from '../components/Loading';
import Text from '../components/Text';
import Title from '../components/Title';
import TimeSelect from '../components/TimeSelect';
import View from '../components/View';
import { calendarDateToTitle } from '../utils/dateUtils';
import { sortByUpdated } from '../utils/sortUtils';

class AllView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showEvents: false
    };

    this.handleLoadDay = this.handleLoadDay.bind(this);
    this.handleLoadEvent = this.handleLoadEvent.bind(this);
  }

  componentDidMount() {
    setTimeout(() => this.setState({ showEvents: true }), 0);
  }

  componentWillReceiveProps(nextProps) {
    const { chart, time } = this.props;
    const { chart: nextChart, time: nextTime } = nextProps;

    if (chart !== nextChart || time !== nextTime) {
      this.setState(
        {
          showEvents: false
        },
        () => {
          setTimeout(() => this.setState({ showEvents: true }), 0);
        }
      );
    }
  }

  handleLoadDay(data) {
    const { history, loadDay } = this.props;

    loadDay(calendarDateToTitle(data.date));

    history.push('/');
  }

  handleLoadEvent(event) {
    const { loadEvent, history } = this.props;

    loadEvent(event);

    history.push('/event');
  }

  render() {
    const { events, history } = this.props;
    const { showEvents } = this.state;

    return (
      <View>
        <Header bodyId="events">
          <BackButton />
          <Title>All activities</Title>
          <HeaderButton onClick={() => history.push('/compare')}>
            <Icon type="chart" />
          </HeaderButton>
        </Header>
        <Body id="events" p="m">
          {!events.length && (
            <Box>
              <Button onClick={() => history.push('/newEvent')} width="full">
                <Icon mr="s" type="add" />New activity
              </Button>
            </Box>
          )}
          {showEvents ? (
            events.map(event => (
              <Box flexDirection="column" key={event.name} pb="m">
                <Text onClick={() => this.handleLoadEvent(event)} textAlign="center" pb="m">
                  {event.name}
                </Text>
                <Chart event={event} />
              </Box>
            ))
          ) : (
            <Loading />
          )}
        </Body>
        <Header className="mobile-column" pb="n">
          <ChartSelect mx="s" mb="m" />
          <TimeSelect mx="s" mb="m" />
        </Header>
      </View>
    );
  }
}

AllView.propTypes = {
  chart: PropTypes.string.isRequired,
  events: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  loadDay: PropTypes.func.isRequired,
  loadEvent: PropTypes.func.isRequired,
  time: PropTypes.string.isRequired
};

const mapStateToProps = ({ chart, events, time }) => ({
  chart,
  events: events.filter(event => !event.hidden).sort(sortByUpdated),
  time
});

export default connect(mapStateToProps, {
  ...activityActions,
  ...dayActions,
  ...eventActions
})(AllView);

// src/store/configStore.js

import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import throttle from 'lodash/throttle';

import rootReducer from '../reducers';
import initialState from '../reducers/initialState';
import { dateToTitle } from '../utils/dateUtils';
import { loadState, saveState } from '../utils/localStorage';

const configStore = () => {
  const preloadedState = window.PRELOADED_STATE;
  const persistedState = loadState();

  const middlewares = [thunk];

  const date = new Date();

  const store = createStore(
    rootReducer,
    {
      ...preloadedState,
      ...persistedState,
      day: dateToTitle(date),
      stats: initialState.stats
    },
    composeWithDevTools(applyMiddleware(...middlewares))
  );

  store.subscribe(
    throttle(() => {
      saveState(store.getState());
    }, 1000)
  );

  return store;
};

export default configStore;

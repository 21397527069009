// src/components/Header.js

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';

import Box from './Box';
import { widths } from '../constants';
import { boxShadow } from '../utils/styleUtils';

const HeaderStyle = styled(Box)`
  backgroundcolor: ${({ backgroundColor: bc }) => bc || 'white'},
    ${({ noBorder }) => !noBorder && boxShadow};
  z-index: ${({ zIndex }) => `${zIndex || 15} !important`};
`;

HeaderStyle.defaultProps = {
  flexShrink: '0',
  p: 'm',
  width: 'full'
};

const HeaderContainer = styled(Box)`
  max-width: ${widths.full};
  ${'' /* max-width: ${widths.contain}; */};
`;

HeaderContainer.defaultProps = {
  alignItems: 'center',
  height: 'full',
  m: '0 auto',
  width: 'contain'
};

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      atTop: true
    };

    (this: any).handleBodyScroll = this.handleBodyScroll.bind(this);
  }

  componentDidMount() {
    const { bodyId } = this.props;

    if (bodyId) {
      const body = document.getElementById(bodyId);
      if (body) {
        this.body = body;
        this.body.addEventListener('scroll', this.handleBodyScroll);
      }
    }
  }

  componentWillUnmount() {
    if (this.body) {
      this.body.removeEventListener('scroll', this.handleBodyScroll);
    }
  }

  body: any;

  handleBodyScroll() {
    const { atTop } = this.state;
    if (this.body && this.body.scrollTop > 0 && atTop) {
      this.setState({ atTop: false });
      return;
    }
    if (this.body && this.body.scrollTop <= 0 && !atTop) {
      this.setState({ atTop: true });
    }
  }

  render() {
    const { children, className, flexDirection, justifyContent, ...props } = this.props;
    const { atTop } = this.state;
    return (
      <HeaderStyle border={!atTop} className="header" {...props}>
        <HeaderContainer
          className={className}
          flexDirection={flexDirection}
          justifyContent={justifyContent}
        >
          {children}
        </HeaderContainer>
      </HeaderStyle>
    );
  }
}

Header.propTypes = {
  bodyId: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  className: PropTypes.string,
  flexDirection: PropTypes.string,
  justifyContent: PropTypes.string
};

Header.defaultProps = {
  bodyId: '',
  children: undefined,
  className: 'header-container',
  flexDirection: 'row',
  justifyContent: 'spaceBetween'
};

export default Header;

// src/components/Error.js

import React from 'react';

import Text from './Text';

const Error = props => <Text {...props} />;

Error.defaultProps = {
  color: 'red',
  textAlign: 'center'
};

export default Error;

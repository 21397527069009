// src/App.js

import ObjectID from 'bson-objectid';
import Chart from 'chart.js';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactChartkick from 'react-chartkick';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as activityActions from './actions/activityActions';
import * as eventActions from './actions/eventActions';
import * as sideMenuActions from './actions/sideMenuActions';
import * as userActions from './actions/userActions';
import Box from './components/Box';
import SideMenu from './components/SideMenu';
import routes from './routes';

ReactChartkick.addAdapter(Chart);

class App extends Component {
  constructor(props) {
    super(props);

    this.handleReload = this.handleReload.bind(this);
  }

  componentDidMount() {
    const {
      closeSideMenu,
      history,
      loadActivities,
      loadEvents,
      loadTodayActivities,
      user,
      createUser
    } = this.props;

    let { _id } = user;

    if (!_id) {
      _id = ObjectID();
      createUser({ _id });

      history.replace('/home');
    }

    closeSideMenu();
    loadActivities(_id);
    loadEvents(_id);
    loadTodayActivities(_id);

    window.heap.identify(_id);

    document.addEventListener('visibilitychange', this.handleReload);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      createUser,
      loadActivities,
      loadEvents,
      loadTodayActivities,
      user: { name }
    } = this.props;
    let { user: { name: nextName, _id } } = nextProps;

    if (!_id) {
      _id = ObjectID();
      createUser({ _id });
    }

    if (!name && nextName) {
      loadActivities(_id);
      loadEvents(_id);
      loadTodayActivities(_id);
      window.heap.identify(_id);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('visibilitychange', this.handleReload);
  }

  handleReload() {
    const { loadActivities, loadEvents, loadTodayActivities, user: { _id } } = this.props;

    if (!document.hidden && _id) {
      loadActivities(_id);
      loadEvents(_id);
      loadTodayActivities(_id);
    }
  }

  render() {
    return (
      <Box className="app" height="100%">
        <Helmet titleTemplate="%s | Aware" defaultTitle="Aware | Free, Daily Activity Tracker" />
        <SideMenu />
        {routes}
      </Box>
    );
  }
}

App.propTypes = {
  closeSideMenu: PropTypes.func.isRequired,
  createUser: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  loadActivities: PropTypes.func.isRequired,
  loadEvents: PropTypes.func.isRequired,
  loadTodayActivities: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
};

const mapStateToProps = ({ user }) => ({
  user
});

export default withRouter(
  connect(mapStateToProps, {
    ...activityActions,
    ...eventActions,
    ...sideMenuActions,
    ...userActions
  })(App)
);

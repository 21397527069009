// src/components/TextArea.js

import React from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import styled from 'styled-components';

import { align, borderRadii, colors, fontSizes, fontWeights, widths } from '../constants';
import { margin, padding, insetBoxShadow } from '../utils/styleUtils';

const TextArea = styled(({ backgroundColor, flexGrow, flexShrink, lineThrough, textAlign, ...rest }) => (
  <TextareaAutosize {...rest} />
))`
  background-color: ${({ backgroundColor: bc }) => colors[bc || 'transparent']};
  border-radius: ${borderRadii.round};
  color: ${colors.black};
  flex: 0 1 auto;
  ${({ flexGrow: fg }) => fg && `flex-grow: ${fg}`};
  font-size: ${fontSizes.m};
  font-weight: ${fontWeights.m};
  ${props => margin(props)};
  min-height: 40px;
  ${props => padding(props)};
  text-align: ${({ textAlign: ta }) => align[ta]};
  &:-webkit-autofill {
    background-color: ${colors.transparent} !important;
  }
  min-width: 50px;
  resize: none;
  width: ${({ width }) => widths[width] || width};
  ${insetBoxShadow};
  &::placeholder {
    color: ${colors.black};
    opacity: 0.5;
  }
`;

TextArea.defaultProps = {
  color: 'black',
  flex: '1 0 auto',
  fs: 'm',
  p: 'm'
};

export default TextArea;

// src/components/ExportButton.js

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import Button from './Button';
import { download } from '../utils/csvUtils';
import { getEventActivities } from '../utils/dataUtils';

const ExportButton = ({ activities, event }) => {
  let eventActivities = getEventActivities(event, activities);

  if (!eventActivities.length) return null;

  return (
    <Button mt="m" onClick={() => download([event], eventActivities)}>
      Export Data
    </Button>
  );
};

ExportButton.propTypes = {
  activities: PropTypes.array.isRequired,
  event: PropTypes.object.isRequired
};

const mapStateToProps = ({ activities }) => ({ activities });

export default connect(mapStateToProps)(ExportButton);

// src/components/CountStats.js

import PropTypes from 'prop-types';
import React from 'react';

import Box from './Box';
import { types } from '../constants';
import Text from './Text';
import { isThisWeek, isThisMonth, isThisYear, titleToDate } from '../utils/dateUtils';
import { calculatePercentString } from '../utils/numberUtils';

const CountStats = ({ activities, event }) => {
  let numberOfActivities = activities.length;

  const { type } = event;

  if (type !== types.SCALE) return null;

  const goal = Number(event.goal);

  const scale = (goal || 5) + 1;
  const counts = new Array(scale).fill(0);
  const thisWeekCounts = new Array(scale).fill(0);
  const thisMonthCounts = new Array(scale).fill(0);
  const thisYearCounts = new Array(scale).fill(0);

  let daysThisWeek = 0;
  let daysThisMonth = 0;
  let daysThisYear = 0;

  for (let i = 0; i < numberOfActivities; i += 1) {
    const currentActivity = activities[i];
    if (!currentActivity.value) continue;
    const currentActivityValue = Number(currentActivity.value);
    const currentActivityDate = titleToDate(currentActivity.date);

    counts[currentActivityValue] += 1;

    if (isThisWeek(currentActivityDate)) {
      daysThisWeek += 1;
      thisWeekCounts[currentActivityValue] += 1;
    }

    if (isThisMonth(currentActivityDate)) {
      daysThisMonth += 1;
      thisMonthCounts[currentActivityValue] += 1;
    }

    if (isThisYear(currentActivityDate)) {
      daysThisYear += 1;
      thisYearCounts[currentActivityValue] += 1;
    }
  }

  return counts.map((count, idx) => {
    if (idx === 0) return null;
    return (
      <Box flexDirection="column" key={`${idx}Day`} pb="m">
        <Box justifyContent="center" pb="m">
          <Text>{idx} Days</Text>
        </Box>
        <Box alignItems="center" justifyContent="spaceBetween" pb="m">
          <Text flexShrink={0}>This Week</Text>
          <Box justifyContent="flexEnd" width="auto">
            <Text color="grey" pr="s">
              ({calculatePercentString(thisWeekCounts[idx], daysThisWeek)})
            </Text>
            <Text textAlign="right">{thisWeekCounts[idx]}</Text>
          </Box>
        </Box>
        <Box alignItems="center" justifyContent="spaceBetween" pb="m">
          <Text flexShrink={0}>This Month</Text>
          <Box justifyContent="flexEnd" width="auto">
            <Text color="grey" pr="s">
              ({calculatePercentString(thisMonthCounts[idx], daysThisMonth)})
            </Text>
            <Text textAlign="right">{thisMonthCounts[idx]}</Text>
          </Box>
        </Box>
        <Box alignItems="center" justifyContent="spaceBetween" pb="m">
          <Text flexShrink={0}>This Year</Text>
          <Box justifyContent="flexEnd" width="auto">
            <Text color="grey" pr="s">
              ({calculatePercentString(thisYearCounts[idx], daysThisYear)})
            </Text>
            <Text textAlign="right">{thisYearCounts[idx]}</Text>
          </Box>
        </Box>
        <Box alignItems="center" justifyContent="spaceBetween" pb="m">
          <Text flexShrink={0}>All Time</Text>
          <Box justifyContent="flexEnd" width="auto">
            <Text color="grey" pr="s">
              ({calculatePercentString(counts[idx], numberOfActivities)})
            </Text>
            <Text textAlign="right">{counts[idx]}</Text>
          </Box>
        </Box>
      </Box>
    );
  });
};

CountStats.propTypes = {
  activities: PropTypes.array.isRequired,
  event: PropTypes.object.isRequired
};

export default CountStats;

// src/components/Title.js

import React from 'react';
import styled from 'styled-components';

import { borderRadii, colors, fontSizes, fontWeights, widths } from '../constants';
import { margin, padding, truncate } from '../utils/styleUtils';
import Clickable from './Clickable';

const Title = styled(
  ({
    backgroundColor,
    children,
    dispatch,
    flexDirection,
    flexGrow,
    flexShrink,
    fontSize,
    left,
    onClick,
    pb,
    textAlign,
    width,
    ...rest
  }) =>
    onClick ? (
      <Clickable alignItems="center" onClick={onClick} {...rest}>
        {children}
      </Clickable>
    ) : (
      <h1 {...rest}>{children}</h1>
    )
)`
  ${({ backgroundColor: bc }) => bc && `background-color: ${colors[bc]}`};
  border-radius: ${({ borderRadius: br }) => borderRadii[br] || br || borderRadii.round};
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'inherit')};
  color: ${({ color }) => colors[color || 'black']};
  flex: 0 1 auto;
  ${({ flexShrink: fs }) => fs && `flex-shrink: ${fs}`};
  ${({ flexGrow: fg }) => fg && `flex-grow: ${fg}`};
  font-size: ${({ fontSize: fs }) => fontSizes[fs] || fs};
  font-weight: ${fontWeights.m};
  letter-spacing: 0.4px;
  margin: 0;
  ${props => margin(props)};
  ${({ mh }) => mh && `min-height: ${mh}`};
  min-width: 48px;
  ${props => padding(props)};
  text-align: ${({ textAlign }) => textAlign || 'center'};
  ${truncate()};
  width: ${({ width }) => widths[width]};
  a {
    color: ${colors.green} !important;
  }
`;

Title.defaultProps = {
  fontSize: 'l',
  width: 'auto'
};

export default Title;

// src/views/ChartsView.js

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { LineChart } from 'react-chartkick';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import BackButton from '../components/BackButton';
import Body from '../components/Body';
import Box from '../components/Box';
import Button from '../components/Button';
import Header from '../components/Header';
import HeaderButton from '../components/HeaderButton';
import Icon from '../components/Icon';
import Label from '../components/Label';
import TimeSelect from '../components/TimeSelect';
import Title from '../components/Title';
import View from '../components/View';
import { types } from '../constants';
import { getEventActivitiesForTime } from '../utils/dataUtils';
import { getChartDay, titleToDate } from '../utils/dateUtils';
import { sortByUpdated } from '../utils/sortUtils';

class ChartsView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      events: []
    };

    this.handleToggleEvent = this.handleToggleEvent.bind(this);
  }

  handleToggleEvent(event) {
    const { events } = this.state;

    if (events.some(e => e.name === event.name)) {
      this.setState({ events: events.filter(e => e.name !== event.name) });
    } else {
      this.setState({ events: [...events, event] });
    }
  }

  render() {
    const { activities, events: allEvents, time } = this.props;
    const { events } = this.state;
    const allData = [];

    const filterEvents = events;

    filterEvents.forEach(event => {
      const eventActivities = getEventActivitiesForTime(event, activities, time);

      if (!eventActivities.length || event.type === types.TEXT) return null;

      const values = {};

      eventActivities.forEach(activity => {
        const date = getChartDay(titleToDate(activity.date));

        if (event.type === types.BOOLEAN) {
          values[date] = Number(activity.value) > 0 ? 1 : 0;
        } else {
          values[date] = Number(activity.value);
        }
      });

      let data = [];

      Object.keys(values).forEach(key => {
        data = [...data, [key, values[key]]];
      });

      allData.push({ name: event.name, data });
    });

    return (
      <View>
        <Helmet>
          <title>Compare</title>
          <meta name="description" content="Compare your Aware activities" />
        </Helmet>
        <Header noBorder zIndex={20} pb="s">
          <BackButton />
          <Title>Compare</Title>
          <HeaderButton hide />
        </Header>
        <Body p="m">
          <LineChart data={allData} className="chart" />
          <Label>Select activities to compare:</Label>
          <Box wrap>
            {allEvents.map(event => (
              <Button
                hide
                key={event._id}
                mb="m"
                mr="m"
                p="s m s n"
                pointer
                onClick={() => this.handleToggleEvent(event)}
              >
                <Icon
                  mr="s"
                  color={events.some(e => e.name === event.name) ? 'green' : 'black'}
                  type={events.some(e => e.name === event.name) ? 'check' : 'checkbox'}
                />
                {event.name}
              </Button>
            ))}
          </Box>
        </Body>
        <Header>
          <TimeSelect />
        </Header>
      </View>
    );
  }
}

ChartsView.propTypes = {
  activities: PropTypes.array.isRequired,
  events: PropTypes.array.isRequired,
  time: PropTypes.string.isRequired
};

const mapStateToProps = ({ activities, events, time }) => ({
  activities,
  events: events.sort(sortByUpdated),
  time
});

export default connect(mapStateToProps)(ChartsView);

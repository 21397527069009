import initialState from './initialState';
import ActionTypes from '../actions/actionTypes';

const userReducer = (state = initialState.user, action) => {
  switch (action.type) {
    case ActionTypes.CREATE_USER_REQUEST:
      return { ...state, _id: action.user._id };

    case ActionTypes.LOGIN_USER:
    case ActionTypes.RESET_PASSWORD_SUCCESS:
    case ActionTypes.SIGNUP_USER:
      return action.user;

    case ActionTypes.LOGOUT:
      return initialState.user;

    default:
      return state;
  }
};

export default userReducer;

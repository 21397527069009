// src/components/Month.js

import PropTypes from 'prop-types';
import React from 'react';
import CalendarHeatmap from 'react-calendar-heatmap';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { withRouter } from 'react-router-dom';

import * as dayActions from '../actions/dayActions';
import Box from './Box';
import { types } from '../constants';
import {
  getCalendarClass,
  getEventActivities,
  getEventActivitiesSearched,
  getTooltip
} from '../utils/dataUtils';
import {
  calendarDateToTitle,
  dateToCalendarDate,
  dateToTitle,
  daysInMonth
} from '../utils/dateUtils';

const Month = ({ activities, event, history, loadDay, search }) => {
  let eventActivities = getEventActivities(event, activities);

  if (event.type === types.TEXT) {
    eventActivities = getEventActivitiesSearched(eventActivities, search);
  }

  const date = new Date();
  const month = date.getMonth();
  const year = date.getFullYear();

  const days = daysInMonth(month + 1, year);

  const dates = [];

  for (let j = 1; j < days + 1; j += 1) {
    const title = dateToTitle(new Date(year, month, j));
    const activity = eventActivities.find(a => a.date === title);
    if (activity) {
      dates.push({
        date: dateToCalendarDate(activity.date),
        count: Number(activity.value),
        value: activity.value
      });
    } else {
      dates.push({
        date: dateToCalendarDate(title),
        count: 0
      });
    }
  }

  return (
    <Box flexDirection="column" pb="m">
      <Box className="month" m="n auto" width="90px">
        <CalendarHeatmap
          onClick={data => {
            loadDay(calendarDateToTitle(data.date));
            history.push('/');
          }}
          startDate={new Date(`${year}-${month + 1}-1`)}
          endDate={new Date(`${year}-${month + 1}-${days - 1}`)}
          values={dates}
          gutterSize={2}
          tooltipDataAttrs={data => getTooltip(data, event)}
          classForValue={data => getCalendarClass(data, event)}
        />
      </Box>
      <ReactTooltip effect="solid" />
    </Box>
  );
};

Month.propTypes = {
  activities: PropTypes.array.isRequired,
  event: PropTypes.object.isRequired,
  loadDay: PropTypes.func.isRequired,
  search: PropTypes.string.isRequired
};

const mapStateToProps = ({ activities, search }) => ({ activities, search });

export default withRouter(connect(mapStateToProps, { ...dayActions })(Month));

// src/views/NewEventView.js

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import * as eventActions from '../actions/eventActions';
import BackButton from '../components/BackButton';
import Body from '../components/Body';
import Box from '../components/Box';
import Button from '../components/Button';
import Error from '../components/Error';
import Header from '../components/Header';
import HeaderButton from '../components/HeaderButton';
import Icon from '../components/Icon';
import Input from '../components/Input';
import Form from '../components/Form';
import Label from '../components/Label';
import SubmitButton from '../components/SubmitButton';
import Title from '../components/Title';
import View from '../components/View';
import { colors, eventColors, lightColorToColor, types, values } from '../constants';
import Event from '../objects/Event';
import { colorToLightColor, colorToDarkColor } from '../utils/stringUtils';

class NewEventView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: ''
    };

    this.clearError = this.clearError.bind(this);
    this.handleCreateEvent = this.handleCreateEvent.bind(this);
  }

  clearError() {
    this.setState({ error: '' });
  }

  handleCreateEvent(e) {
    e.preventDefault();

    this.clearError();

    const { createEvent, history, newEvent, user } = this.props;

    if (!newEvent.name) {
      this.setState({ error: 'Name your activity' });
      return;
    }

    newEvent.user_id = user._id;

    const event = new Event(newEvent);

    createEvent(event);

    history.goBack();
  }

  render() {
    const {
      changeEventColor,
      changeEventGoal,
      changeEventName,
      changeEventType,
      history,
      newEvent
    } = this.props;
    const { error } = this.state;

    const isValid = !!newEvent.name;

    return (
      <View>
        <Header>
          <BackButton />
          <Title>New activity</Title>
          <HeaderButton hide />
        </Header>
        {error && <Error>{error}</Error>}
        <Body p="n m">
          <Form onSubmit={this.handleCreateEvent}>
            <Label color={error ? 'red' : null}>Name</Label>
            <Box>
              <Input
                autoFocus
                onChange={e => {
                  this.clearError();
                  changeEventName(e.target.value);
                }}
                placeholder="ex: read, walk, meditate"
                value={newEvent.name}
                width="full"
              />
              <Button
                ml="m"
                onClick={() => {
                  this.clearError();
                  history.push('/ideas');
                }}
              >
                Ideas
              </Button>
            </Box>
            <Label>Type</Label>
            <Button
              alignItems="center"
              justifyContent="flexStart"
              mb="m"
              onClick={() => {
                this.clearError();
                changeEventType(types.COUNT);
              }}
              width="full"
            >
              <Icon mr="m" type={newEvent.type === types.COUNT ? 'unselect' : 'select'} />
              Count
            </Button>
            <Button
              alignItems="center"
              justifyContent="flexStart"
              mb="m"
              onClick={() => {
                this.clearError();
                changeEventType(types.BOOLEAN);
              }}
              width="full"
            >
              <Icon mr="m" type={newEvent.type === types.BOOLEAN ? 'unselect' : 'select'} />
              Yes / No
            </Button>
            <Button
              alignItems="center"
              justifyContent="flexStart"
              mb="m"
              onClick={() => {
                this.clearError();
                changeEventType(types.TEXT);
              }}
              width="full"
            >
              <Icon mr="m" type={newEvent.type === types.TEXT ? 'unselect' : 'select'} />
              Text
            </Button>
            <Button
              alignItems="center"
              justifyContent="flexStart"
              onClick={() => {
                this.clearError();
                changeEventType(types.SCALE);
              }}
              width="full"
            >
              <Icon mr="m" type={newEvent.type === types.SCALE ? 'unselect' : 'select'} />
              Range (ex. 1-5)
            </Button>
            {newEvent.type === types.COUNT && <Label>Set your daily goal (optional)</Label>}
            {newEvent.type === types.BOOLEAN && <Label>Is your goal yes or no? (optional)</Label>}
            {newEvent.type === types.SCALE && <Label>Range from 1 up to...</Label>}
            {(newEvent.type === types.COUNT || newEvent.type === types.SCALE) && (
              <Input
                onChange={e => {
                  this.clearError();
                  changeEventGoal(e.target.value);
                }}
                placeholder="ex: 3, 5, 10"
                value={newEvent.goal}
                width="full"
              />
            )}
            {newEvent.type === types.BOOLEAN && (
              <Box>
                <Button
                  flexGrow={1}
                  backgroundColor={newEvent.goal === values.ZERO ? 'red' : null}
                  onClick={() => {
                    this.clearError();
                    changeEventGoal(values.ZERO);
                  }}
                >
                  No
                </Button>
                <Button
                  flexGrow={1}
                  backgroundColor={newEvent.goal === values.ONE ? 'green' : null}
                  ml="m"
                  onClick={() => {
                    this.clearError();
                    changeEventGoal(values.ONE);
                  }}
                >
                  Yes
                </Button>
              </Box>
            )}
            <Label>Pick a color (optional)</Label>
            <Box wrap>
              {eventColors.map(c => (
                <Button
                  borderRadius="circle"
                  key={c}
                  noMin
                  onClick={() =>
                    lightColorToColor[c] === newEvent.color
                      ? changeEventColor('')
                      : changeEventColor(lightColorToColor[c])}
                  mb="m"
                  mr="m"
                >
                  <Icon
                    color={
                      colors[colorToLightColor(newEvent.color)] === c
                        ? colorToDarkColor(newEvent.color)
                        : c
                    }
                    type="unselect"
                  />
                </Button>
              ))}
            </Box>
            <input type="submit" style={{ display: 'none' }} />
          </Form>
        </Body>
        <Header>
          <SubmitButton backgroundColor={isValid ? 'green' : null} onClick={this.handleCreateEvent}>
            Create
          </SubmitButton>
        </Header>
      </View>
    );
  }
}

NewEventView.propTypes = {
  changeEventColor: PropTypes.func.isRequired,
  changeEventGoal: PropTypes.func.isRequired,
  changeEventName: PropTypes.func.isRequired,
  changeEventType: PropTypes.func.isRequired,
  createEvent: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  newEvent: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

const mapStateToProps = ({ newEvent, user }) => ({ newEvent, user });

export default connect(mapStateToProps, {
  ...eventActions
})(NewEventView);

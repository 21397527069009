import { types } from '../constants';
import { isStreaking, isYesterday } from './dateUtils';

export const didMeetGoal = (value, event) => {
  let { goal } = event;

  if (goal) {
    goal = Number(goal);

    if (goal) return value >= goal;
    return value === goal;
  }
  return value >= 1;
};

export const getIsStreaking = (activities) => {
  let isStreaking = false;
  activities.forEach(activity => {
    const isActivityYesterday = isYesterday(activity.date);

    if (isActivityYesterday) {
      isStreaking = true;
    }
  });

  return isStreaking;
}

export const getCurrentStreak = (activities, event) => {
  if (activities.length === 0) return 0;

  let date = null;
  let streak = 0;

  const isText = event.type === types.TEXT;

  activities.forEach(activity => {
    // first activity, set streak to zero
    if (!date) {
      streak = 0;
    }

    const streaking = isStreaking(activity.date, date);

    // broke streak and activity is not today
    if (!streaking) {
      streak = 0;
    }

    const value = isText
      ? activity.value ? 1 : 0
      : Number(activity.value);

    const metGoal = didMeetGoal(value, event);

    if (metGoal) {
      streak += 1;
    } else {
      streak = 0;
    }

    date = activity.date;
  });

  return streak;
};

export const getLongestStreak = (activities, event) => {
  if (activities.length === 0) return 0;

  let date = null;

  let streak = 0;
  let longestStreak = 0;

  const isText = event.type === types.TEXT;

  activities.forEach(activity => {
    // first activity, set streak to zero
    if (!date) {
      streak = 0;
    }

    const streaking = isStreaking(activity.date, date);

    // broke streak and activity is not today
    if (!streaking) {
      streak = 0;
    }

    const value = isText
      ? activity.value ? 1 : 0
      : Number(activity.value);

    const metGoal = didMeetGoal(value, event);

    // met goal but broke streak aka met goal today
    if (metGoal) {
      streak += 1;
    } else {
      streak = 0;
    }

    if (streak > longestStreak) longestStreak = streak;

    date = activity.date;
  });

  return longestStreak;
};

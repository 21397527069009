// src/components/Icon/index.js

import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { colors, iconSizes } from '../constants';
import { margin, padding, position } from '../utils/styleUtils';
import iconTypes from '../iconTypes';

const IconStyle = styled.div`
  align-items: center;
  color: ${({ color }) => colors[color || 'black'] || color};
  display: inline;
  flex-shrink: 0;
  max-height: 24px;
  min-height: 24px;
  line-height: 1;
  ${({ type }) => type === 'parent' && 'transform: rotate(45deg)'};
  ${props => margin(props)};
  ${props => padding(props)};
  ${props => position(props)};
  svg {
    position: relative;
  }
`;

const Icon = ({ size, type, ...props }) => {
  const IconType = iconTypes[type];
  return (
    <IconStyle type={type} {...props}>
      <IconType size={iconSizes[size] || size} />
    </IconStyle>
  );
};

Icon.propTypes = {
  size: PropTypes.string,
  type: PropTypes.string.isRequired
};

Icon.defaultProps = {
  size: 'm'
};

export default Icon;

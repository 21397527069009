import initialState from './initialState';
import ActionTypes from '../actions/actionTypes';

const chartReducer = (state = initialState.chart, action) => {
  switch (action.type) {
    case ActionTypes.NEXT_CHART: {
      const { chart } = action;

      let nextChart;
      if (chart === 'Grid') {
        nextChart = 'Line';
      } else if (chart === 'Line') {
        nextChart = 'Pie';
      } else if (chart === 'Pie') {
        nextChart = 'Bar';
      } else if (chart === 'Bar') {
        nextChart = 'Days';
      } else if (chart === 'Days') {
        nextChart = 'Grid';
      }

      return nextChart;
    }

    case ActionTypes.PAST_CHART: {
      const { chart } = action;

      let pastChart;
      if (chart === 'Grid') {
        pastChart = 'Days';
      } else if (chart === 'Days') {
        pastChart = 'Bar';
      } else if (chart === 'Bar') {
        pastChart = 'Pie';
      } else if (chart === 'Pie') {
        pastChart = 'Line';
      } else if (chart === 'Line') {
        pastChart = 'Grid';
      }

      return pastChart;
    }

    case ActionTypes.SET_CHART:
      return action.chart;

    default:
      return state;
  }
};

export default chartReducer;

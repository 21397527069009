// src/components/LineChart.js

import PropTypes from 'prop-types';
import React from 'react';
import { LineChart } from 'react-chartkick';
import { connect } from 'react-redux';

import Box from './Box';
import { colors, types } from '../constants';
import { getEventActivitiesForTime, getStartDateWithTime } from '../utils/dataUtils';
import { dateToTitle, getChartDay, titleToDate } from '../utils/dateUtils';
import { colorToDarkColor } from '../utils/stringUtils';

const LineChartComponent = ({ activities, event, time }) => {
  let eventActivities = getEventActivitiesForTime(event, activities, time);

  const isText = event.type === types.TEXT;

  if (!eventActivities.length || isText) return null;

  const isBoolean = event.type === types.BOOLEAN;

  const values = {};

  // do we want to show zeros?
  if (event.zeros) {
    // first date to check
    const startDate = getStartDateWithTime(event, eventActivities, time);

    // last date to check
    const today = new Date();

    // dates to check
    const dateTitles = [];
    let currentDate = new Date(startDate);
    while (currentDate <= today) {
      currentDate = new Date(currentDate);
      dateTitles.push(dateToTitle(currentDate));
      currentDate = currentDate.setDate(currentDate.getDate() + 1);
    }

    // map over each day to get values
    dateTitles.forEach(dateTitle => {
      const activity = eventActivities.find(a => a.date === dateTitle);
      if (!activity || !activity.value) {
        values[dateTitle] = 0;
      } else {
        const value = Number(activity.value);
        values[dateTitle] = isBoolean ? (value > 0 ? 1 : 0) : value;
      }
    });
  } else {
    eventActivities.forEach(activity => {
      if (!activity.value) return;
      const date = getChartDay(titleToDate(activity.date));
      const value = Number(activity.value);
      values[date] = isBoolean ? (value > 0 ? 1 : 0) : value;
    });
  }

  let data = [{ name: event.name, data: {} }];
  let goal;
  let min = Number.MAX_VALUE;
  let max = Number.MIN_VALUE;

  const hasGoal = !!event.goal && event.type !== types.SCALE;
  if (hasGoal) {
    goal = Number(event.goal);
    data[1] = { name: 'Goal', data: {} };
  }

  Object.keys(values).forEach(key => {
    const value = values[key];
    if (value > max) max = value;
    if (min > value) min = value;
    data[0].data[key] = value;

    if (hasGoal) {
      data[1].data[key] = goal;
    }
  });

  if (hasGoal) {
    if (min > goal) {
      min = goal;
    }
    if (max < goal) {
      max = goal;
    }
  }

  return (
    <Box flexDirection="column" pb="m">
      <LineChart
        data={data}
        className="chart"
        colors={[colors[event.color ? colorToDarkColor(event.color) : 'green']]}
        curve={false}
        legend={!hasGoal}
        min={min}
        max={max}
      />
    </Box>
  );
};

LineChartComponent.propTypes = {
  activities: PropTypes.array.isRequired,
  event: PropTypes.object.isRequired,
  time: PropTypes.string.isRequired
};

const mapStateToProps = ({ activities, time }) => ({ activities, time });

export default connect(mapStateToProps)(LineChartComponent);

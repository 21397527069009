import PropTypes from 'prop-types';
import React from 'react';

import { borderRadii, colors, sizes, widths } from '../constants';

const styles = ({ onClick, width }) => ({
  content: {
    background: colors.white,
    border: 'none',
    borderRadius: borderRadii.button,
    boxShadow: '0 0 20px 4px rgba(0, 0, 0, 0.2)',
    cursor: 'auto',
    display: 'flex',
    flexDirection: 'column',
    left: '50%',
    mb: sizes.m,
    maxWidth: widths[width] || widths.s,
    outline: 'none',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: '85%',
    zIndex: 20
  },
  overlay: {
    backgroundColor: colors.haze,
    bottom: 0,
    cursor: onClick ? 'pointer' : 'auto',
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 15
  }
});

const Modal = ({ children, ...props }) => {
  const style = styles(props);
  return (
    <div style={style.overlay} {...props}>
      <div
        onClick={e => e.stopPropagation && e.stopPropagation()}
        onKeyUp={() => {}}
        role="button"
        style={style.content}
        tabIndex={-1}
      >
        {children}
      </div>
    </div>
  );
};

Modal.propTypes = {
  children: PropTypes.any.isRequired,
  onClick: PropTypes.func.isRequired
};

export default Modal;

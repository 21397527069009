import initialState from './initialState';
import ActionTypes from '../actions/actionTypes';

const loadingReducer = (state = initialState.loading, action) => {
  switch (action.type) {
    case ActionTypes.LOAD_TODAY_ACTIVITIES_REQUEST:
    case ActionTypes.REQUEST:
      return true;

    case ActionTypes.EMAIL_CODE_SUCCESS:
    case ActionTypes.ERROR:
    case ActionTypes.LOAD_TODAY_ACTIVITIES_SUCCESS:
    case ActionTypes.LOGIN_USER:
    case ActionTypes.RESET_PASSWORD_SUCCESS:
    case ActionTypes.SIGNUP_USER:
      return false;

    default:
      return state;
  }
};

export default loadingReducer;

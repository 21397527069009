import ObjectID from 'bson-objectid';

class Activity {
  constructor(activity) {
    this._id = activity._id || ObjectID().toString();
    this.event_id = activity.event_id;
    this.date = activity.date;
    this.created = new Date();
    this.updated = new Date();
    this.value = activity.value;
    this.user_id = activity.user_id;
  }
}

export default Activity;

// src/views/ChartSelect.js

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import * as chartActions from '../actions/chartActions';
import Box from '../components/Box';
import Button from '../components/Button';
import { charts } from '../constants';

const ChartSelect = ({ nextChart, pastChart, chart, setChart, ...props }) => {
  const [hasMounted, setHasMounted] = useState(false);

  // eslint-disable-next-line
  useEffect(() => (hasMounted ? false : setHasMounted(true)), []);

  return (
    hasMounted && (
      <Box alignItems="center" borderRadius="button" boxShadow noOverflow {...props}>
        {Object.values(charts).map(c => (
          <Button
            backgroundColor="transparent"
            borderRadius="none"
            flexGrow="1"
            hover={chart !== c}
            key={c}
            onClick={() => setChart(c)}
          >
            {c}
          </Button>
        ))}
      </Box>
    )
  );
};

ChartSelect.propTypes = {
  nextChart: PropTypes.func.isRequired,
  pastChart: PropTypes.func.isRequired,
  chart: PropTypes.string.isRequired
};

const mapStateToProps = ({ chart }) => ({ chart });

export default connect(mapStateToProps, { ...chartActions })(ChartSelect);

import styled from 'styled-components';

import { colors } from '../constants';

const View = styled.div`
  background-color: ${colors.white};
  bottom: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
`;

export default View;

import initialState from './initialState';
import ActionTypes from '../actions/actionTypes';

const loadingAllReducer = (state = initialState.loadingAll, action) => {
  switch (action.type) {
    case ActionTypes.LOAD_ACTIVITIES_REQUEST:
    case ActionTypes.REQUEST:
      return true;

    case ActionTypes.ERROR:
    case ActionTypes.LOAD_ACTIVITIES_SUCCESS:
      return false;

    default:
      return state;
  }
};

export default loadingAllReducer;

export const capitalizeFirstLetter = str => str.charAt(0).toUpperCase() + str.substr(1);

/* eslint-disable */
export const checkEmail = str =>
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    str
  );

export const colorToLightColor = color => `light${capitalizeFirstLetter(color || '')}`;
export const colorToDarkColor = color => `dark${capitalizeFirstLetter(color || '')}`;

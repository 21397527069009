import initialState from './initialState';
import ActionTypes from '../actions/actionTypes';

const errorReducer = (state = initialState.error, action) => {
  switch (action.type) {
    case ActionTypes.CLEAR_ERROR:
      return '';

    case ActionTypes.ERROR:
      return action.error;

    default:
      return state;
  }
};

export default errorReducer;

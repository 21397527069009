// src/components/Chart.js

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import BarChart from './BarChart';
import DayBarChart from './DayBarChart';
import Grid from './Grid';
import LineChart from './LineChart';
import PieChart from './PieChart';
import { charts } from '../constants';

const Chart = ({ event, chart }) => {
  if (chart === charts.BAR) return <BarChart event={event} />;
  if (chart === charts.DAYS) return <DayBarChart event={event} />;
  if (chart === charts.GRID) return <Grid event={event} />;
  if (chart === charts.LINE) return <LineChart event={event} />;
  if (chart === charts.PIE) return <PieChart event={event} />;
  return null;
};

Chart.propTypes = {
  event: PropTypes.object,
  chart: PropTypes.string.isRequired
};

Chart.defaultProps = {
  event: null
};

const mapStateToProps = ({ chart }) => ({ chart });

export default connect(mapStateToProps)(Chart);

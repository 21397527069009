import initialState from './initialState';
import ActionTypes from '../actions/actionTypes';

const timeReducer = (state = initialState.time, action) => {
  switch (action.type) {
    case ActionTypes.NEXT_TIME: {
      const { time } = action;

      let nextTime;
      if (time === 'Week') {
        nextTime = 'Month';
      } else if (time === 'Month') {
        nextTime = 'Year';
      } else if (time === 'Year') {
        nextTime = 'All-Time';
      } else if (time === 'All-Time') {
        nextTime = 'Week';
      }

      return nextTime;
    }

    case ActionTypes.PAST_TIME: {
      const { time } = action;

      let pastTime;
      if (time === 'Week') {
        pastTime = 'All-Time';
      } else if (time === 'Month') {
        pastTime = 'Week';
      } else if (time === 'Year') {
        pastTime = 'Month';
      } else if (time === 'All-Time') {
        pastTime = 'Year';
      }

      return pastTime;
    }

    case ActionTypes.SET_TIME:
      return action.time;

    default:
      return state;
  }
};

export default timeReducer;

import ObjectID from 'bson-objectid';

class Event {
  constructor(event) {
    this._id = event._id || ObjectID().toString();
    this.name = event.name;
    this.created = new Date();
    this.hidden = false;
    this.updated = new Date();
    this.type = event.type || 'count';
    this.goal = event.goal;
    this.units = event.units;
    this.user_id = event.user_id;
    this.color = '';
    this.incrementValue = '';
  }
}

export default Event;

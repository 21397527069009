// src/components/Loading.js

import React from 'react';

import Text from './Text';

const Loading = props => <Text {...props}>Loading...</Text>;

Loading.defaultProps = {
  backgroundColor: 'lightGrey',
  color: 'grey',
  textAlign: 'center',
  p: 'm'
};

export default Loading;

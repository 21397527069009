import initialState from './initialState';
import ActionTypes from '../actions/actionTypes';

const sideMenuReducer = (state = initialState.sideMenu, action) => {
  switch (action.type) {
    case ActionTypes.CLOSE_SIDE_MENU:
      return false;

    case ActionTypes.TOGGLE_SIDE_MENU:
      return !state;

    default:
      return state;
  }
};

export default sideMenuReducer;

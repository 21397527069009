// src/components/Week.js

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as dayActions from '../actions/dayActions';
import Box from './Box';
import Text from './Text';
import { shortDays, times, types, weekDays } from '../constants';
import {
  getCalendarClass,
  getEventActivitiesForTime,
  getEventActivitiesSearched
} from '../utils/dataUtils';
import { calendarDateToTitle, dateToCalendarDate, titleToDate } from '../utils/dateUtils';

const Week = ({ activities, event, history, loadDay, search }) => {
  let eventActivities = getEventActivitiesForTime(event, activities, times.WEEK);

  if (event.type === types.TEXT) {
    eventActivities = getEventActivitiesSearched(eventActivities, search);
  }

  const numberOfActivities = eventActivities.length;

  const days = new Array(7).fill(0);

  for (let i = 0; i < numberOfActivities; i += 1) {
    const currentActivity = eventActivities[i];
    const currentActivityDate = titleToDate(currentActivity.date);

    const dayOfWeek = currentActivityDate.getDay();
    days[dayOfWeek] = currentActivity;
  }

  const dates = [];
  days.forEach(
    day =>
      day
        ? dates.push({
            date: dateToCalendarDate(day.date),
            count: Number(day.value),
            value: day.value
          })
        : dates.push({
            date: null,
            count: 0
          })
  );

  return (
    <Box pb="m">
      {dates.slice(1).map((day, idx) => (
        <Box
          alignItems="center"
          flexDirection="column"
          flexGrow={1}
          key={weekDays[idx + 1]}
          onClick={() => {
            loadDay(calendarDateToTitle(day.date));
            history.push('/');
          }}
        >
          <Text className="web-text" fontSize="s" mb="s">
            {weekDays[idx + 1]}
          </Text>
          <Text className="mobile-text" fontSize="s" mb="s">
            {shortDays[idx + 1]}
          </Text>
          <Box className={getCalendarClass(day, event)} p="m" />
        </Box>
      ))}
      <Box alignItems="center" flexDirection="column" flexGrow={1} key={weekDays[0]}>
        <Text className="web-text" fontSize="s" mb="s">
          {weekDays[0]}
        </Text>
        <Text className="mobile-text" fontSize="s" mb="s">
          {shortDays[0]}
        </Text>
        <Box className={getCalendarClass(dates[0], event)} p="m" />
      </Box>
    </Box>
  );
};

Week.propTypes = {
  activities: PropTypes.array.isRequired,
  event: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  loadDay: PropTypes.func.isRequired,
  search: PropTypes.string.isRequired
};

const mapStateToProps = ({ activities, search }) => ({ activities, search });

export default withRouter(connect(mapStateToProps, { ...dayActions })(Week));

// src/components/RecordStats.js

import PropTypes from 'prop-types';
import React from 'react';

import Box from './Box';
import { types } from '../constants';
import Text from './Text';
import { getStartDateWithTime } from '../utils/dataUtils';
import { dateToTitle, isThisWeek, isThisMonth, isThisYear, titleToDate } from '../utils/dateUtils';

const RecordStats = ({ activities, event }) => {
  const { type } = event;

  if (type !== types.COUNT) return null;

  let largestAllTimeCount = Number.MIN_VALUE;
  let largestWeekCount = Number.MIN_VALUE;
  let largestMonthCount = Number.MIN_VALUE;
  let largestYearCount = Number.MIN_VALUE;
  let smallestAllTimeCount = Number.MAX_VALUE;
  let smallestWeekCount = Number.MAX_VALUE;
  let smallestMonthCount = Number.MAX_VALUE;
  let smallestYearCount = Number.MAX_VALUE;

  if (event.zeros) {
    // first date to check
    const startDate = getStartDateWithTime(event, activities);

    // last date to check
    const today = new Date();

    // dates to check
    const dateTitles = [];
    let currentDate = new Date(startDate);
    while (currentDate <= today) {
      currentDate = new Date(currentDate);
      dateTitles.push(dateToTitle(currentDate));
      currentDate = currentDate.setDate(currentDate.getDate() + 1);
    }

    // map over each day to get values
    dateTitles.forEach(dateTitle => {
      const activity = activities.find(a => a.date === dateTitle);

      let activityValue;
      let activityDate;
      if (activity && activity.value) {
        activityValue = Number(activity.value);
        activityDate = titleToDate(activity.date);
      } else {
        activityValue = 0;
        activityDate = titleToDate(dateTitle);
      }

      if (activityValue > largestAllTimeCount) largestAllTimeCount = activityValue;
      if (activityValue < smallestAllTimeCount) smallestAllTimeCount = activityValue;

      if (isThisWeek(activityDate)) {
        if (activityValue > largestWeekCount) largestWeekCount = activityValue;
        if (activityValue < smallestWeekCount) smallestWeekCount = activityValue;
      }

      if (isThisMonth(activityDate)) {
        if (activityValue > largestMonthCount) largestMonthCount = activityValue;
        if (activityValue < smallestMonthCount) smallestMonthCount = activityValue;
      }

      if (isThisYear(activityDate)) {
        if (activityValue > largestYearCount) largestYearCount = activityValue;
        if (activityValue < smallestYearCount) smallestYearCount = activityValue;
      }
    });
  } else {
    activities.forEach(activity => {
      if (!activity.value) return;
      const activityValue = Number(activity.value);
      const activityDate = titleToDate(activity.date);

      if (activityValue > largestAllTimeCount) largestAllTimeCount = activityValue;
      if (activityValue < smallestAllTimeCount) smallestAllTimeCount = activityValue;

      if (isThisWeek(activityDate)) {
        if (activityValue > largestWeekCount) largestWeekCount = activityValue;
        if (activityValue < smallestWeekCount) smallestWeekCount = activityValue;
      }

      if (isThisMonth(activityDate)) {
        if (activityValue > largestMonthCount) largestMonthCount = activityValue;
        if (activityValue < smallestMonthCount) smallestMonthCount = activityValue;
      }

      if (isThisYear(activityDate)) {
        if (activityValue > largestYearCount) largestYearCount = activityValue;
        if (activityValue < smallestYearCount) smallestYearCount = activityValue;
      }
    });
  }

  return (
    <Box flexDirection="column" pb="m">
      <Box justifyContent="center" pb="m">
        <Text>Record Highs</Text>
      </Box>
      <Box alignItems="center" justifyContent="spaceBetween" pb="m">
        <Text flexShrink={0}>This Week</Text>
        <Text textAlign="right">
          {largestWeekCount === Number.MIN_VALUE ? 0 : largestWeekCount}
        </Text>
      </Box>
      <Box alignItems="center" justifyContent="spaceBetween" pb="m">
        <Text flexShrink={0}>This Month</Text>
        <Text textAlign="right">
          {largestMonthCount === Number.MIN_VALUE ? 0 : largestMonthCount}
        </Text>
      </Box>
      <Box alignItems="center" justifyContent="spaceBetween" pb="m">
        <Text flexShrink={0}>This Year</Text>
        <Text textAlign="right">
          {largestYearCount === Number.MIN_VALUE ? 0 : largestYearCount}
        </Text>
      </Box>
      <Box alignItems="center" justifyContent="spaceBetween" pb="m">
        <Text flexShrink={0}>All-Time</Text>
        <Text textAlign="right">
          {largestAllTimeCount === Number.MIN_VALUE ? 0 : largestAllTimeCount}
        </Text>
      </Box>
      <Box justifyContent="center" pb="m">
        <Text>Record Lows</Text>
      </Box>
      <Box alignItems="center" justifyContent="spaceBetween" pb="m">
        <Text flexShrink={0}>This Week</Text>
        <Text textAlign="right">
          {smallestWeekCount === Number.MAX_VALUE ? 0 : smallestWeekCount}
        </Text>
      </Box>
      <Box alignItems="center" justifyContent="spaceBetween" pb="m">
        <Text flexShrink={0}>This Month</Text>
        <Text textAlign="right">
          {smallestMonthCount === Number.MAX_VALUE ? 0 : smallestMonthCount}
        </Text>
      </Box>
      <Box alignItems="center" justifyContent="spaceBetween" pb="m">
        <Text flexShrink={0}>This Year</Text>
        <Text textAlign="right">
          {smallestYearCount === Number.MAX_VALUE ? 0 : smallestYearCount}
        </Text>
      </Box>
      <Box alignItems="center" justifyContent="spaceBetween" pb="m">
        <Text flexShrink={0}>All-Time</Text>
        <Text textAlign="right">
          {smallestAllTimeCount === Number.MAX_VALUE ? 0 : smallestAllTimeCount}
        </Text>
      </Box>
    </Box>
  );
};

RecordStats.propTypes = {
  activities: PropTypes.array.isRequired,
  event: PropTypes.object.isRequired
};

export default RecordStats;

// src/components/Year.js

import PropTypes from 'prop-types';
import React from 'react';
import CalendarHeatmap from 'react-calendar-heatmap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import * as dayActions from '../actions/dayActions';
import Box from './Box';
import { types } from '../constants';
import Text from './Text';
import {
  getCalendarClass,
  getEventActivitiesForYear,
  getEventActivitiesSearched,
  getTooltip
} from '../utils/dataUtils';
import {
  calendarDateToTitle,
  dateToCalendarDate,
  dateToTitle,
  daysInMonth
} from '../utils/dateUtils';

const Year = ({ activities, event, history, loadDay, search, showYear, year, ...props }) => {
  let eventActivities = getEventActivitiesForYear(event, activities, year);

  if (event.type === types.TEXT) {
    eventActivities = getEventActivitiesSearched(eventActivities, search);
  }

  const dates = [];

  for (let i = 1; i < 13; i += 1) {
    const days = daysInMonth(i, year);
    for (let j = 1; j < days + 1; j += 1) {
      const title = dateToTitle(new Date(year, i - 1, j));
      const activity = eventActivities.find(a => a.date === title);
      if (activity) {
        dates.push({
          date: dateToCalendarDate(activity.date),
          count: Number(activity.value),
          value: activity.value
        });
      } else {
        dates.push({
          date: dateToCalendarDate(title),
          count: 0
        });
      }
    }
  }

  return (
    <Box flexDirection="column" pb="m" {...props}>
      {showYear && (
        <Text pb="s" textAlign="center" width="full">
          {year}
        </Text>
      )}
      <CalendarHeatmap
        onClick={data => {
          loadDay(calendarDateToTitle(data.date));
          history.push('/');
        }}
        startDate={new Date(`${year - 1}-12-31`)}
        endDate={new Date(`${year}-12-31`)}
        values={dates}
        gutterSize={2}
        tooltipDataAttrs={data => getTooltip(data, event)}
        classForValue={data => getCalendarClass(data, event)}
      />
      <ReactTooltip effect="solid" />
    </Box>
  );
};

Year.propTypes = {
  activities: PropTypes.array.isRequired,
  event: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  loadDay: PropTypes.func.isRequired,
  search: PropTypes.string.isRequired,
  showYear: PropTypes.bool,
  year: PropTypes.number.isRequired
};

Year.defaultProps = {
  showYear: false
};

const mapStateToProps = ({ activities, search }) => ({ activities, search });

export default withRouter(connect(mapStateToProps, { ...dayActions })(Year));

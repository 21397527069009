import initialState from './initialState';
import ActionTypes from '../actions/actionTypes';
import { getTodaysTitle } from '../utils/dateUtils';

const activitiesReducer = (state = initialState.activities, action) => {
  switch (action.type) {
    case ActionTypes.LOAD_TODAY_ACTIVITIES_SUCCESS: {
      let existingActivities = state.slice();

      const { activities } = action;

      activities.forEach(activity => {
        const index = existingActivities.findIndex(a => a._id === activity._id);

        if (index === -1) {
          existingActivities = [...existingActivities, activity];
        } else {
          const existingActivity = existingActivities[index];
          const existingActivityUpdated = new Date(existingActivity.updated);
          const activityUpdated = new Date(activity.updated);
          if (existingActivityUpdated < activityUpdated) {
            existingActivities = [...existingActivities.slice(0, index), activity, ...existingActivities.slice(index + 1)];
          }
        }
      });

      return existingActivities;
    }

    case ActionTypes.LOAD_ACTIVITIES_SUCCESS: {
      let oldActivities = state.slice();

      // let newActivities = state.slice();

      const { activities } = action;

      const today = getTodaysTitle();

      // activities.forEach(activity => {
      //   const index = newActivities.findIndex(a => a._id === activity._id);
      //   if (index === -1) {
      //     newActivities = [...newActivities, activity];
      //   } else {
      //     const a = newActivities[index];
      //     if (a.date !== today) {
      //       newActivities = [
      //         ...newActivities.slice(0, index),
      //         activity,
      //         ...newActivities.slice(index + 1)
      //       ];
      //     }
      //   }
      // });

      // only save todays activities
      oldActivities = oldActivities.filter(a => a.date === today);
      // only update activities from past days
      const newActivities = activities.filter(a => a.date !== today);

      // return newActivities;

      return [...oldActivities, ...newActivities];
    }

    case ActionTypes.CREATE_ACTIVITY:
    case ActionTypes.SAVE_ACTIVITY: {
      const index = state.findIndex(activity => activity._id === action.activity._id);
      return index === -1
        ? [...state, action.activity]
        : [...state.slice(0, index), action.activity, ...state.slice(index + 1)];
    }

    case ActionTypes.LOGOUT:
      return initialState.activities;

    default:
      return state;
  }
};

export default activitiesReducer;

// src/components/BackButton.js

import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';

import HeaderButton from './HeaderButton';
import Icon from './Icon';

const BackButton = ({ history, ...props }) => (
  <HeaderButton onClick={history.goBack} {...props}>
    <Icon type="back" />
  </HeaderButton>
);

BackButton.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(BackButton);

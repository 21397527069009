import initialState from './initialState';
import ActionTypes from '../actions/actionTypes';

const eventsReducer = (state = initialState.events, action) => {
  switch (action.type) {
    case ActionTypes.CHANGE_EVENT_INCREMENT_VALUE:
      return state.map(event =>
        event._id === action.event._id ? { ...event, incrementValue: action.incrementValue } : event);

    case ActionTypes.CREATE_EVENT:
      return [...state, action.event];

    case ActionTypes.CREATED_EVENT:
      return state.map(event => (event.name === action.event.name ? action.event : event));

    case ActionTypes.DELETE_EVENT:
      return state.filter(event => event._id !== action.event._id);

    case ActionTypes.LOAD_EVENTS:
      return action.events;

    case ActionTypes.SAVE_EVENT:
      return state.map(event => (event._id === action.event._id ? action.event : event));

    case ActionTypes.UPDATE_EVENT:
      return state.map(event =>
        event._id === action.event._id ? { ...event, updated: action.event.updated } : event);

    case ActionTypes.LOGOUT:
      return initialState.events;

    default:
      return state;
  }
};

export default eventsReducer;

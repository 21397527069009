// src/components/Layout/Body.js

import styled from 'styled-components';
import React from 'react';

import { colors, widths } from '../constants';
import { padding } from '../utils/styleUtils';
import Clickable from './Clickable';

// 'contain']};

const Container = styled(Clickable)`
  background-color: ${({ backgroundColor: bc }) => colors[bc]};
  max-width: ${({ width }) => widths[width]};
  ${({ opacity }) => opacity && 'opacity: 0.5'};
  position: relative;
  ${({ textAlign }) => textAlign && `text-align: ${textAlign}`};
  width: 100%;
`;

Container.defaultProps = {
  flexDirection: 'column',
  flexShrink: '0',
  height: 'auto',
  m: '0 auto',
  width: 'contain'
};

const BodyStyles = styled.div`
  background-color: transparent;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  -webkit-overflow-scrolling: touch;
  ${props => padding(props)};
`;

const Body = ({ id, dispatch, p, ...props }) => (
  <BodyStyles id={id} p={p}>
    <Container {...props} />
  </BodyStyles>
);

export default Body;

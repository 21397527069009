// src/routes.js

import React from 'react';
import { Route, Switch } from 'react-router-dom';
import loadable from '@loadable/component';

import AllView from './views/AllView';
import ChartsView from './views/ChartsView';
import DayView from './views/DayView';
import EventView from './views/EventView';
import HomeView from './views/HomeView';
import HowToVideosView from './views/HowToVideosView';
import IdeasView from './views/IdeasView';
import NewEventView from './views/NewEventView';
import SignupView from './views/SignupView';

const EditEventView = loadable(() => import('./views/EditEventView'));
const ForgotPasswordView = loadable(() => import('./views/ForgotPasswordView'));
const HiddenView = loadable(() => import('./views/HiddenView'));
const NotFoundView = loadable(() => import('./views/NotFoundView'));
const PrivacyView = loadable(() => import('./views/PrivacyView'));
const ReorderView = loadable(() => import('./views/ReorderView'));
const ResetPasswordView = loadable(() => import('./views/ResetPasswordView'));
const StatsView = loadable(() => import('./views/StatsView'));
const TermsView = loadable(() => import('./views/TermsView'));

export default (
  <Switch>
    <Route exact path="/" component={DayView} />
    <Route exact path="/all" component={AllView} />
    <Route exact path="/charts" component={ChartsView} />
    <Route exact path="/compare" component={ChartsView} />
    <Route exact path="/editEvent" component={EditEventView} />
    <Route exact path="/event" component={EventView} />
    <Route exact path="/forgotPassword" component={ForgotPasswordView} />
    <Route exact path="/hidden" component={HiddenView} />
    <Route exact path="/home" component={HomeView} />
    <Route exact path="/ideas" component={IdeasView} />
    <Route exact path="/newEvent" component={NewEventView} />
    <Route exact path="/privacy" component={PrivacyView} />
    <Route exact path="/reorder" component={ReorderView} />
    <Route exact path="/resetPassword" component={ResetPasswordView} />
    <Route exact path="/signup" component={SignupView} />
    <Route exact path="/stats" component={StatsView} />
    <Route exact path="/terms" component={TermsView} />
    <Route exact path="/videos" component={HowToVideosView} />
    <Route path="*" component={NotFoundView} />
  </Switch>
);

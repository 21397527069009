// src/components/AllTime.js

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as dayActions from '../actions/dayActions';
import Box from './Box';
import Year from './Year';
import { getEventActivities } from '../utils/dataUtils';
import { titleToDate } from '../utils/dateUtils';

const AllTime = ({ activities, event }) => {
  const eventActivities = getEventActivities(event, activities);

  let small = new Date();
  let curr = null;
  eventActivities.forEach(activity => {
    curr = new Date(titleToDate(activity.date));
    if (small > curr) {
      small = curr;
    }
  });
  const startYear = small.getFullYear();

  const date = new Date();
  const year = date.getFullYear();

  let years = [];
  for (let y = startYear; y <= year; y += 1) {
    years = [...years, <Year event={event} key={y} showYear year={y} />];
  }

  return (
    <Box flexDirection="column" pb="m">
      {years}
    </Box>
  );
};

AllTime.propTypes = {
  activities: PropTypes.array.isRequired,
  event: PropTypes.object.isRequired
};

const mapStateToProps = ({ activities }) => ({ activities });

export default withRouter(connect(mapStateToProps, { ...dayActions })(AllTime));

// src/components/DayStats.js

import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import Box from './Box';
import { types, weekDays } from '../constants';
import Text from './Text';
import { getDaysSinceStart } from '../utils/dataUtils';
import { titleToDate } from '../utils/dateUtils';
import { calculateAverage, calculatePercentString } from '../utils/numberUtils';
import { didMeetGoal } from '../utils/statUtils';

const DayStats = ({ activities, event, search }) => {
  const numberOfDays = getDaysSinceStart(event, activities) / 7;

  const { type } = event;

  const isBoolean = type === types.BOOLEAN;
  const isCount = type === types.COUNT;
  const isText = type === types.TEXT;

  let days = new Array(7).fill(0);
  const daysCount = new Array(7).fill(0);
  const daysHit = new Array(7).fill(0);

  activities.forEach(activity => {
    const activityDate = titleToDate(activity.date);

    let activityValue;
    if (isText) {
      if (search) {
        const searchRegularExpression = new RegExp(search, 'g');
        activityValue = (activity.value.match(searchRegularExpression) || []).length;
      } else {
        activityValue = activity.value ? 1 : 0;
      }
    } else {
      activityValue = Number(activity.value);
    }

    const hitGoal = didMeetGoal(activityValue, event);

    if (isBoolean) activityValue = Number(hitGoal);

    const dayOfWeek = activityDate.getDay();
    days[dayOfWeek] += 1;
    daysCount[dayOfWeek] += activityValue;

    if (hitGoal) daysHit[dayOfWeek] += 1;
  });

  days = days.slice(1);

  return (
    <Box flexDirection="column" pb="m">
      {days.map((day, idx) => (
        <Fragment key={weekDays[idx + 1]}>
          <Box justifyContent="center" pb="m">
            <Text>{weekDays[idx + 1]}</Text>
          </Box>
          <Box alignItems="center" justifyContent="spaceBetween" pb="m">
            <Text>Total</Text>
            <Text textAlign="right">{daysCount[idx + 1]}</Text>
          </Box>
          {!isBoolean &&
            !isText && (
              <Box alignItems="center" justifyContent="spaceBetween" pb="m">
                <Text>Average</Text>
                <Text textAlign="right">{calculateAverage(daysCount[idx + 1], numberOfDays)}</Text>
              </Box>
            )}
          {isText && (
            <Box alignItems="center" justifyContent="spaceBetween" pb="m">
              <Text>Percent</Text>
              <Text textAlign="right">
                {calculatePercentString(daysCount[idx + 1], numberOfDays)}
              </Text>
            </Box>
          )}
          {isCount &&
            event.goal && (
              <Box alignItems="center" justifyContent="spaceBetween" pb="m">
                <Text flexShrink={0}>Days Hit Goal</Text>
                <Text textAlign="right">{daysHit[idx + 1]}</Text>
              </Box>
            )}
          {(isBoolean || isCount) && (
            <Box alignItems="center" justifyContent="spaceBetween" pb="m">
              <Text>Percent</Text>
              <Text textAlign="right">
                {calculatePercentString(daysHit[idx + 1], numberOfDays)}
              </Text>
            </Box>
          )}
        </Fragment>
      ))}
      <Fragment key={weekDays[0]}>
        <Box justifyContent="center" pb="m">
          <Text>{weekDays[0]}</Text>
        </Box>
        <Box alignItems="center" justifyContent="spaceBetween" pb="m">
          <Text>Total</Text>
          <Text textAlign="right">{daysCount[0]}</Text>
        </Box>
        {!isBoolean &&
          !isText && (
            <Box alignItems="center" justifyContent="spaceBetween" pb="m">
              <Text>Average</Text>
              <Text textAlign="right">{calculateAverage(daysCount[0], numberOfDays)}</Text>
            </Box>
          )}
        {isText && (
          <Box alignItems="center" justifyContent="spaceBetween" pb="m">
            <Text>Percent</Text>
            <Text textAlign="right">{calculatePercentString(daysCount[0], numberOfDays)}</Text>
          </Box>
        )}
        {isCount &&
          event.goal && (
            <Box alignItems="center" justifyContent="spaceBetween" pb="m">
              <Text flexShrink={0}>Days Hit Goal</Text>
              <Text textAlign="right">{daysHit[0]}</Text>
            </Box>
          )}
        {(isBoolean || isCount) && (
          <Box alignItems="center" justifyContent="spaceBetween" pb="m">
            <Text>Percent</Text>
            <Text textAlign="right">{calculatePercentString(daysHit[0], numberOfDays)}</Text>
          </Box>
        )}
      </Fragment>
    </Box>
  );
};

DayStats.propTypes = {
  activities: PropTypes.array.isRequired,
  event: PropTypes.object.isRequired,
  search: PropTypes.string,
};

export default DayStats;
